import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';

const EMDRSelector = ({state, control, slide }) => {
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>


        <div
          className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
          style={{
              maxWidth:"25rem"
          }}
        >
          <div className=" flex-column w-100">
            <h3 className="text-center mb-4">Please choose your session</h3>
            <br />


            {
                state.emdrSessions.map((e, index)=>{
                    return <div key= {"button:"+index} onClick={
                        ()=>{
                          control.setEMDRSession(e.noTrigger)
                        }
                    }
                      className="btn border border-secondary w-100 mb-2 py-3 text-left"
                    >
                      {e.noTrigger}
                    </div>

                })
            }



<div onClick={
                        (e)=>{
                            control.goWhere('homePage')
                        }
                    }
                      className="btn border border-secondary w-100 mb-2 py-3 text-left"
                    >
                      Go Homepage
                    </div>





        
          </div>

         <EmergencyResources/>
        </div>

    </>
  );
};
export default EMDRSelector;
