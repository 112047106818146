import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';

const EMDRJournal = ({ state, control, slide }) => {
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>

      <div
          className="  container d-flex  justify-content-center vh-100 mh-100 "
          style={{
              maxWidth:"25rem"
          }}
        >
          <div className=" flex-column w-100">

          <h3 className="m-4">EMDR Journal</h3>
  

           

            <div className="mt-2">
              Date
              <textarea name="name" rows="1" className="w-100 "></textarea>
            </div>
            <div className="mt-2">
            Do you recall a moment in the past week that was mildy disturbing?
              <textarea name="name" rows="3" className="w-100 "></textarea>
            </div>
            <div className="mt-2">
              What was the trigger?
              <textarea name="name" rows="3" className="w-100 "></textarea>
            </div>
            <div className="mt-2">
              What was the image that came to your mind?
              <textarea name="name" rows="2" className="w-100 "></textarea>
            </div>
            <div className="mt-2">
              What belief/ cognition emerged for you?
              <textarea name="name" rows="1" className="w-100 "></textarea>
            </div>
            <div className="mt-2">
              What emotion did you feel?
              <textarea name="name" rows="2" className="w-100"></textarea>
            </div>
            <div class="mt-2">
              Take a minute to do a body scan. Where do you feel it in your body? Notice the sensations you are feeling in your body and breifly write about it below
              <textarea name="name" rows="2" class="w-100"></textarea>
            </div>

            <div className="mt-2">
            How distressed do you feel when you think about that incident now? Write your distress score, where 1 is the least distressed and 10 is the most distressed.
              <textarea name="name" rows="2" className="w-100 "></textarea>
            </div>

            <div className="btn-group w-100 mt-4 mb-5" role="group" aria-label="">
              <button type="button" className="btn border border-secondary  text-left mr-2"
              
              onClick={()=>{
                control.goSlide(slide.complete)
              }}
              >Add another  journal entry</button>
              {/* <!-- next goes to home page --> */}
            </div>



          <EmergencyResources />
        </div>
        </div>
   




    </>
  );
};
export default EMDRJournal;