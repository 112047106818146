import EmergencyResources from "../Components/EmergencyResources";
import Navbar from "../Components/Navbar";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

const checkBoxCss = { marginRight: "20px" };

const RowHistory = ({ index, event }) => {
  return (
    <tr>
      <td>{event}</td>
      <td style={{ 
          textAlign: "center" }}>
        <Form.Check
          inline
          name={"textarea" + index}
          label=""
          type="textarea"
          id={"inline-textarea-" + index}
        />
      </td>
    </tr>
  );
};




const PatientChecklist = ({ state, control, slide }) => {
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>
      <Container className="d-flex  justify-content-center vh-100 mh-100 ">
        <div className=" flex-column w-100">
     
                 
          <h3>EMDR Consent</h3>
          <br />
          <Form>
          <Form.Label> 
          Please read each statement carefully. 
          If you agree with the statement, 
          please write your initials in the space
          provided. If you have any questions or  
          concerns, please talk with your therapist 
          before completing this checklist.
               
 
              </Form.Label>
          <Table responsive="sm">
            <thead>
              <tr>
                <th></th>
                <th>Initial</th>
              </tr>
            </thead>
            <tbody>
              <RowHistory
                event={
                  "I have rapport, i.e., a trusting relationship with my therapist and I am willing to tell my therapist the truth about what I am experiencing."
                }
                index={0}
              />
              <RowHistory event={"I am committed/dedicated to both my own safety and treatment."} index={1} />
              <RowHistory
                event={
                  "I have skills to handle high levels of emotion."
                }
                index={2}
              />
              <RowHistory
                event={
                  "I was able to do the resourcing development and installation."
                }
                index={3}
              />
              <RowHistory
                event={
                  "I have imaginal resources that I can use to calm and comfort myself."
                }
                index={4}
              />
              <RowHistory
                event={
                  "I have an adequate support system that includes, but is not limited to, my therapist."
                }
                index={4}
              />
              <RowHistory
                event={
                  "I am not taking medications in the Benzodiazepine class. (e.g., end in “pam”)- AND Either my medication is effective or I am stable without medication."
                }
                index={5}
              />
              <RowHistory
                event={
                  "My health and safety are not in jeopardy from substance use/abuse; am not in active addiction."
                }
                index={6}
              />
              <RowHistory
                event={
                   "Self-harming behaviors are not my primary method of coping with affect/emotions/relationship troubles. If this was/is an issue for me, have adequately addressed it in therapy."}
                index={7}
              />
              <RowHistory
                event={
                  "I do not feel suicidal."
                }
                index={8}
              />
              <RowHistory
                event={
                  "I have not been diagnosed with a dissociative disorder"
                }
                index={9}
              />
              <RowHistory event={"I have been given the screening, Dissociative Experience Scale ll (DES II) for dissociative disorder and have discussed the results with my therapist."} index={10} />
              <RowHistory event={
                  "I am not involved in an active legal case -OR- I am involved in a legal case. I have been informed that by reprocessing the material in question, my legal testimony may be impaired."
                  } index={11} />
             <RowHistory
                event={
                  "I do not have any concerns about my safety with the relationships I am in, either emotionally or physically."
                }
                index={12}
              />
             <RowHistory
                event={
                  "I have never been hospitalized for self-harm, suicidal ideation or behaviour."
                }
                index={13}
              />
               <RowHistory
                event={
                  "I have not actively engaged in any sort of addiction at a clinical level. Addictions can be substances, eating disorders etc."
                }
                index={14}
              />
              <RowHistory
                event={"I have read, understood and signed an Informed Consent to begin EMDR sessions"}
                index={15}
              />
            </tbody>
          </Table>
          </Form>
          <Form>
            
            <button
              onClick={() => {
                control.goSlide(slide.complete)
              }}
              type="button"
              className="btn btn-block border text-center btn-lg mb-2"
            >
               {slide.complete.title}
            </button>
            <div style={{paddingTop:"50px"}}></div>
          </Form>
        </div>
        
      </Container>
      <EmergencyResources />
    </>
  );
};

export default PatientChecklist;
