import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';
import {Recorder} from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'
 
import { useState } from "react";

const RecordAudioButton = ({slide,control})=>{
  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0
    }
  });


  const handleAudioStop = (data)=>{
    setAudioDetails(data)
}
const handleAudioUpload = (file)=> {
  const objectURL = URL.createObjectURL(file)
  control.saveAudio(objectURL)
  control.goSlide(slide.quit);
}
const handleRest = ()=> {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0
      }
    };
    setAudioDetails(reset )
  }

return <>

<Recorder
    record={true}
    title={"New recording"}
    audioURL={audioDetails.url}
    handleAudioStop={data => handleAudioStop(data)}
    handleAudioUpload={data => handleAudioUpload(data)}
    handleRest={() => handleRest()} 
/>
</>
}



const RecordAudio = ({state, control, slide }) => {
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>

   
      <div className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 " style={{maxWidth:"25rem"}} >
      <div className=" flex-column w-100">

        <h3 className="text-center">{slide.title}</h3><br/>

    
          <div className="text-center my-3">
        </div>
            <div className="text-center my-3">
             
            
              <RecordAudioButton slide={slide} control={control}/>
            
            </div>
          
           <div className="float-right mt-4">

         
           
              {slide.quit?<button onClick={()=>{
              //control.saveAudio(doc)
              control.goSlide(slide.quit);
          }} type="button" className="btn border border-secondary float-right mt-3 mb-4 ml-2 "style={{width:"120px"}} >Quit</button>:<></>}
             
             
             {slide.complete?<button
            type="button"
            className="btn btn-block border  text-center btn-lg mt-4"
            onClick={()=>{
              control.goSlide(slide.complete);
            }}
          >
            Next
          </button>:<></>}
             
             
            </div>
            <EmergencyResources/>
        
      </div>
    </div>

    </>
  );
};
export default RecordAudio;

