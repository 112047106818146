import EmergencyResources from "../Components/EmergencyResources";
import Navbar from "../Components/Navbar";
import Sidepanel from "../Components/Sidepanel";

import "@fontsource/open-sans";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import ConvertText from '../Helper/ConvertText'


const Homepage = ({ state, control, slide }) => {

  return (
    <>
    <Navbar state={state} control={control} slide={slide}/>

      <Row className="bg-img min-vh-100"  >
       
       <Sidepanel state={state} control={control} slide={slide}/>

         <div style={{}} className="box">
         <div
        className="  container d-flex  justify-content-center  mh-100 "
        style={{
          maxWidth: "35rem"
        }}>
          <div className=" flex-column w-100">
          
          <h1 tabindex={8} className="mb-4">{ConvertText(slide.title, state.name)}</h1>
          <p tabindex={9} className="text-left mb-5" style={{ fontSize:"16px", lineHeight:""}}>{slide.blurb}</p>
          {slide.buttons.map((button,index)=>{

            // if (button.meta==="wet"){
                        
            //   if (!state.bdiAssessment===0 && state.consent){
            //     control.goSlide("homePage");
            //     return ;
            //   }
            // }
// Display calm place and container on home page once set up from within emdr 
            // if (button.meta==="calmSpace"){
            
            //   if (!state.entries.length && !state.audio.length){
            //     return <></>
            //   }
            // }
            

            // if (button.meta==='container'){
            //   if (!state.containers.length){
            //     return <></>
            //   }
            // }



              return <div key={"Button"+index} tabindex={index+10} onClick={
                ()=>{
                  if (button.meta==="container"){
                    if (state.containers.length>0){
                      control.goSlide(button.containerList);
                      return;
                    }

                  }
                  control.goSlide(button)
                }
            }
              style = {{borderColor:"#006fde", fontSize:"14px", maxWidth:'400px', margin:'auto', borderRadius:"30px"}}className=" mb-3 btn  btn-block text-center btn-lg "
            >
              {button.title}
            </div>


            })}
            <button tabindex={20} style = {{fontSize:"14px",backgroundColor:"#006fde", color:"white", maxWidth:'400px', margin:'auto', borderRadius:"30px"}}className=" btn  btn-block border text-center btn-lg " onClick={()=>{
        control.goWhere('signUp')}}
       > Sign up</button>
         
<ul className="list-group mt-5">
  <h3 tabindex={21} className="text-left">Additional resources:</h3>
  
<li  className="list-group text-left mb-2" tabindex={22} ><a  href="https://www.savyntech.com/post/mental-health-support-lines" target="_blank" rel="noreferrer">Mental health support lines</a></li>
<li className="list-group text-left mb-2"tabindex={23}><a  href="https://www.savyntech.com/post/how-to-find-the-right-therapist-for-you"  target="_blank" rel="noreferrer" >Finding the right therapist for you</a></li>
  <li className="list-group text-left mb-2" tabindex={24}><a  href="https://www.savyntech.com/post/finding-your-calm-safe-space-in-ptsd-recovery"  target="_blank" rel="noreferrer">Finding your calm place in PTSD recovery</a></li>
  <li className="list-group text-left mb-2" tabindex={25}><a  href="https://www.savyntech.com/post/self-soothing-using-the-five-senses"  target="_blank" rel="noreferrer">Self-soothing using five senses</a></li>
  <li className="list-group text-left " tabindex={26} ><a  href="https://open.spotify.com/user/v0sadk1etz0q2m1v6z035jvw4?si=Ek4eHbzcRCeFOdHbSbxo9w&dl_branch=1"  target="_blank" rel="noreferrer">Savyn's Spotify playlist for relaxation</a></li>
</ul>

<h3 tabindex={27} className="text-left mt-5" >Follow us</h3>
        <a tabindex={28}href="https://www.linkedin.com/company/savyn/" target="_blank" rel="noreferrer" >
          <img 
            src="https://storage.googleapis.com/savyn-public/In-Black-48px-R.png"
            height="30px"
            className="float-left my-1 mx-2"
          />
        </a>
        <a tabindex={29} href="https://www.instagram.com/savyn.tech/" target="_blank" rel="noreferrer" >
          <img 
            src="https://storage.googleapis.com/savyn-public/glyph-logo_May2016.png" 
            height="30px"
            className="float-left my-1 mx-2"
          />
        </a>
        <br/>
        <div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

<br/>
<div tabindex={30} className=" text-left " style={{width:"100%", fontSize:"12px" }}>
If you’re in crisis, please call the Toronto Distress Line at 416-408-4357, Canada Suicide Prevention Services at 1-833-456-4566, Kids Help Line  at 1-800-668-6868 or the Gerstein Crisis Centre at 416-929-5200. You may also choose to go to your local hospital emergency room or call 911.

</div>
<div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>


</div>
</div>
</div>

{/* <EmergencyResources /> */}
</Row>

    </>
  );
};

export default Homepage;
