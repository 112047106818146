const EmergencyResources = () => {
  return (
  
    <a
      href="#"
      className=" fixed-bottom btn text-white  bg-primary border" style={{ borderRadius:"0", height:"35px", fontSize:"14px", overflowWrap:"break-word", hyphens:"auto"}}
      role="button"
    >
      If you are in crisis please call 911 or the Toronto Distress Line (416-408-4357) or Canada Suicide Prevention Service (1-833-456-4566) or Kids Help Phone (1-800-668-6868) or go to your local hospital emergency room{" "}
      <i className="fa fa-warning fa-lg ml-auto" style={{ color: "gray" }}></i>
    </a>
  );
};
export default EmergencyResources;
