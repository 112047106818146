
import gql from "graphql-tag";

/*
addMessage(Stub: Boolean, jwt: String, message: String!): Boolean!
const addMessage =  gql`
  mutation addMessage($uuid: String!, $json:String!) {
    answerAForm(uuid: $uuid, json: $json)
  }
`;
*/

const addDebug = gql`
mutation addDebug($message:JSON!){
  addDebug(message:$message)
}
`;


const combine = {
  //  addMessage
  addDebug
};

export default combine;
