
// import EmergencyResources from '../Components/EmergencyResources';
// import Navbar from '../Components/Navbar';
// import { useState } from "react";
// import Countdown from 'react-countdown';


// const Completionist = () => <>Next</>;
// const renderer = ({ hours, minutes, seconds, completed }) => {
//   if (completed) {
//     // Render a completed state
//     return <Completionist />;
//   } else {
//     // Render a countdown
//     return <>{(seconds===0)?60:seconds}</>;
//   }
// };

// const WriteWet = ({ state, control, slide }) => {
  
//   // const [title, setTitle] = useState("");
//   // const [doc, setDoc] = useState("");

//   return (
//     <>

//       <Navbar state={state} control={control} slide={slide}/>

//       <div className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 " style={{ maxWidth: "25rem" }}>
//         <div className=" flex-column w-100">

//           <h3 className="text-center my-4">{slide.title}</h3>
//           <p className = "mb-4 ">{slide.description}</p>
//           <br/>

//           {/* <textarea onChange={(e)=>{
//             setDoc(e.target.value);
//           }} className="form-control border border-secondary mb-3 mt-n3" name="" rows="12" ></textarea> */}
//           {/* <p>Give it a name. Choose a cue word that best describes it.</p>
//           <textarea onChange={(e)=>{
//             setTitle(e.target.value);
//           }} className="form-control border border-secondary mt-3" name="" rows="1" ></textarea> */}

//           {/* <button onClick={() => {
//             control.goSlide(slide.quit);
//           }} type="button" className="btn border border-secondary float-right mt-3 mb-4 ml-2 " style={{ width: "120px" }}>Quit</button>
//           <button onClick={() => {
//             control.saveWet(doc,title)
//             control.goSlide(slide.save);
//           }} type="button" className="btn border border-secondary float-right mt-3 mb-4 ml-2 " style={{ width: "120px" }}>Save</button>
//            */}
//           {/* {slide.complete?<button
//             type="button"
//             className="btn btn-block border  text-center btn-lg mt-4"
//             onClick={()=>{
              
//             control.goSlide(slide.complete);
//             }}
//           >
//             <Countdown
//     date={Date.now() + slide.time}
//     renderer={renderer}
//   />
           
//           </button>:<></>}
//           */}
//                     <button
//             type="button"
//             className="btn btn-block border  text-center btn-lg mt-4"
//             onClick={()=>{

//             control.goSlide(slide.complete);
//             }}
//           >
//             <Countdown
//     date={Date.now() + slide.time}
//     renderer={renderer}
//   />
//           </button>


//           <EmergencyResources />
//         </div>
//       </div>
//     </>
//   );
// };





// export default WriteWet;


import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';
import React from 'react';
import { useState } from "react";
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';


const Completionist = () => <>Next</>;

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    // return <>{(seconds===0)?60:seconds}</>;
    return <span>{minutes}:{seconds}</span>;
  }
};

const WriteWet = ({ state, control, slide }) => {
// const [title, setTitle] = useState("");
const [doc, setDoc] = useState("");
  return (
    <>

      <Navbar state={state} control={control} slide={slide}/>

      <div className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 " style={{ maxWidth: "25rem" }}>
        <div className=" flex-column w-100">

          <h3 className="text-center my-4">{slide.title}</h3><br/>
          <p className="mb-4">{slide.description}</p>

 <textarea onChange={(e)=>{
             setDoc(e.target.value);

          }} className="form-control border border-secondary mb-3 mt-n3" name="" rows="12" ></textarea> 


          

          <button
            type="button"
            className="btn btn-block border  text-center btn-lg mt-4"
            onClick={()=>{
            control.saveWet(doc)
              
            control.goSlide(slide.complete);
            }}
          >
            <Countdown
    date={Date.now() + 1800000}
    renderer={renderer}
  />
          </button>
         


          <EmergencyResources />
        </div>
      </div>
    </>
  );
};





export default WriteWet;

