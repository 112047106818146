import withoutShowdow from "../Images/without_shadow.png";
import { useState } from "react";
import "@fontsource/open-sans";

import {Navbar, NavDropdown, Nav, Form} from 'react-bootstrap';

const _Sidepanel = ({ state, control, slide }) => {

  return <>
   
     
   <div className=" d-none d-lg-block bg-white pt-2 pr-3 pl-3 " style={{width:"340px"}}>
   <Navbar.Brand tabindex={28} href="/" className="ml-3 text-dark" >
  <img 
        alt=""
        src={withoutShowdow}
        width="40"
        height="40"
        className="d-inline-block align-top"
      />{' '}
      <h1 style={{fontSize:'30px'}}  className="d-inline-block align-top"> 
      <a style={{textDecoration:"none", color:"#343a40"}} href="/">SAVYN</a></h1>
  </Navbar.Brand>
  <NavDropdown.Divider />

<ul className=" list-group  ">
        
    <li className="list-unstyled mb-2  "> 
    <a tabindex={33} onClick={()=>{
          control.goWhere("homePage");}}  
          style={{fontSize:"16px"}}
          className=" text-dark d-lg-block listItem  " >
            <i class="fa fa-home " aria-hidden="true"></i>
            <div className="d-inline-block pl-2">Home</div> </a></li>

    <li className="list-unstyled mb-2 "> 
    <a tabindex={34} onClick={()=>{
          control.goWhere("breathExercise");}}  
          style={{fontSize:"16px"}}
          className=" text-dark d-lg-block listItem ">
            <i class="fa fa-wind " aria-hidden="true"></i>
            <div className="d-inline-block pl-2"> Square breathing</div> </a></li>

    <li className="list-unstyled mb-2"> 
    <a tabindex={35} onClick={()=>{
        control.goWhere('calmPlace')}} 
        style={{fontSize:"16px"}} 
        className="text-dark d-lg-block listItem">
        <i class="fa fa-seedling " aria-hidden="true"></i> 
        <div className="d-inline-block pl-2">Visit a calm place </div> </a></li>

    <li className="list-unstyled mb-2"> 
    <a tabindex={36} onClick={()=>{
        control.goWhere('bodyScan')}} 
        style={{fontSize:"16px"}} 
        className="text-dark d-lg-block listItem">
          <i class="fa fa-male px-1"></i> 
          <div className="d-inline-block pl-2">Body scan</div> </a></li>

    <li className="list-unstyled mb-2"> 
    <a tabindex={37} onClick={()=>{
        control.goWhere('container')}}
        style={{fontSize:"16px"}}
        className="text-dark d-lg-block listItem">
          <i class="fa fa-box archive" aria-hidden="true"></i> 
          <div className="d-inline-block pl-2">Put your thoughts away in containers</div></a></li>


    <li className="list-unstyled mb-2 "> 
    <a tabindex={38} onClick={()=>{
        control.goWhere('gratitude')}}
        style={{fontSize:"16px"}}
        className="text-dark d-lg-block listItem">
          <i class="fa fa-book " aria-hidden="true"></i> 
          <div className="d-inline-block pl-2">Gratitude journal</div></a></li>

    <li className="list-unstyled mb-2  " >
    <a tabindex={39}  onClick={()=>{
      control.goWhere("traumaTriggers");}}
      style={{fontSize:"16px"}}
      className="text-dark d-lg-block listItem">
        <i class="fa fa-chalkboard " aria-hidden="true"></i> 
        <div className="d-inline-block pl-2">Trauma and triggers </div></a></li>
      
    <li className="list-unstyled mb-2 "> 
    <a tabindex={40} onClick={()=>{
        control.goWhere('breathExerciseArabic')}}
        style={{fontSize:"16px"}}
        className="text-dark d-lg-block listItem">
          <i class="fa fa-wind " aria-hidden="true"></i>
          <div className="d-inline-block pl-2">التنفس التأمل  </div></a></li>
         
    <li className="list-unstyled mb-2 "> 
    <a tabindex={41} onClick={()=>{
        control.goWhere('breathExerciseMandarin')}}
        style={{fontSize:"16px"}}
        className="text-dark d-lg-block listItem">
          <i class="fa fa-wind " aria-hidden="true"></i>
          <div className="d-inline-block pl-2">盒子呼吸法 </div></a></li>
    <li className="list-unstyled mb-2 "> 
    <a tabindex={42} onClick={()=>{
        control.goWhere('about')}}
        style={{fontSize:"16px"}}
        className="text-dark d-lg-block listItem">
          <i class="fas fa-users"></i>
          <div className="d-inline-block pl-2">About</div></a></li>
   
          <li className="list-unstyled mb-2 "> 
    <a tabindex={43} onClick={()=>{
        control.goWhere('advisors')}}
        style={{fontSize:"16px"}}
        className="text-dark d-lg-block listItem">
         <i class="fas fa-address-book"></i>
          <div className="d-inline-block pl-2">Advisors</div></a></li>
   

    <li className="list-unstyled mb-2 "> 
    <a tabindex={44} onClick={()=>{
        control.goWhere('terms')}}
        style={{fontSize:"16px"}}
        className="text-dark d-lg-block listItem">
          <i class="far fa-file-alt"></i>
          <div className="d-inline-block pl-2"> Terms </div></a></li>
    

</ul>
</div>
   
</>
};
export default _Sidepanel;
