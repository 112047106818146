import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";


import { useState, useEffect } from "react";

const MultiSelect = ({state, control, slide }) => {

  const [selectState, setSelectState] =useState({})







  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>


        <div
          className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
          style={{
              maxWidth:"25rem"
          }}
        >
          <div className=" flex-column w-100">
            <h3 className="text-center mb-4">{slide.title}</h3>
            <p>{slide.description}</p>
            <br />

            {slide.buttons.map((button, index)=>{
              return <div key= {"button:"+index} onClick={
                (e)=>{
                  var other = {...selectState}
                  other[button.title] = !selectState[button.title];
                  setSelectState(other);
                }
            }
              className="btn border border-secondary w-100 mb-2 py-3 text-left"
            >
              {selectState[button.title]?button.title + " -> Selected":button.title}
            </div>


            })}

            {slide.writeYourOwn?<>
            
              <Form>
            <Form.Group controlId="userName">
              <Form.Label>Write You Own:</Form.Label>
              <Form.Control type="text" placeholder="Write Your Own" />
            </Form.Group>
            </Form>
            
           
            
            <div onClick={
              ()=>{
                control.goSlide(slide.writeYourOwn)
              }
          }
            className="btn border border-secondary w-100 mb-2 py-3 text-left"
          >
            {slide.writeYourOwn.title}
          </div>
          </>
            :<></>}
        
          </div>

         <EmergencyResources/>
        </div>

    </>
  );
};
export default MultiSelect;
