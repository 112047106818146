// import EmergencyResources from "../Components/EmergencyResources";
// import Navbar from "../Components/Navbar";
// import { useState } from "react";
// import SetSUDS from "../Templates/SetSUDS";

// import {
//     InputGroup,
//     FormControl,
//   } from "react-bootstrap";
// const JournalEntries = ({ state, control, slide }) => {
//   let [selectedSlide, setSelectedSlide] = useState(undefined);
//   let [goIn, setGoIn] = useState(false);
//   let [checkSuds, setCheckSuds] = useState(false);
//   let [text, setText] = useState("")


//   // if (checkSuds) {
//   //   console.log("hi there")

//   //   let writtenExpose = slide.entries[selectedSlide];

//   //   return (
//   //     <>
//   //       {/* <Navbar state={state} control={control} slide={slide}/> */}

//   //       {/* <div
//   //         className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
//   //         style={{ maxWidth: "25rem" }}
//   //       > */}
//   //         <SetSUDS state={state} control={control} slide={slide} />            
//   //         <button 
//   //             type="button" 
//   //             className="btn btn-block border text-center btn-lg mb-2"
//   //             onClick={()=>{
//   //               // setText(state.writtenExposure[selectedSlide])
//   //               setCheckSuds(false);
//   //               // setSelectedSlide(undefined);
//   //               setGoIn(true);
//   //             }}>Next hello</button>


//   //         <div className=" flex-column w-100">
//   //           {/* <h3>{writtenExpose.slideTitle}</h3>
//   //           <br />
//   //           <p>{writtenExpose.slideDesc}</p> */}



//   //           {/* <button
//   //             type="submit"
//   //             className="btn btn-block border  text-center btn-lg mt-4"
//   //             onClick={() => {
//   //               setText(state.writtenExposure[selectedSlide])
//   //               setCheckSuds(false);
//   //             }}
//   //           >
//   //             Next
//   //           </button> */}

//   //           <EmergencyResources />
//   //         </div>
//   //       {/* </div> */}
//   //     </>
//   //   );
//   // }
//   if (goIn){
//     console.log("here")
//     let writtenExpose = slide.entries[selectedSlide];

//     return (
//         <>


//           <Navbar state={state} control={control} slide={slide}/>
    
//           <div
//             className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
//             style={{ maxWidth: "25rem" }}
//           >
//             <div className=" flex-column w-100">
            

          

//               <h3>{writtenExpose.slideWrite}</h3>


//               <InputGroup>
//                 <FormControl as="textarea" aria-label="With textarea" onChange={(e)=>{
// setText(e.target.value);
//                 }} value={text}/>
//               </InputGroup>

  
//               <button
//                 type="submit"
//                 className="btn btn-block border  text-center btn-lg mt-4"
//                 onClick={() => {
//                     control.addWrittenExposure(text,selectedSlide)
//                     setGoIn(false);
//                     setSelectedSlide(undefined);
//                     setText("");
//                 }}
//               >
//                 Save Doc
//               </button>
  
//               <EmergencyResources />
//             </div>
//           </div>
//         </>
//       );


//   }


//   if (selectedSlide !== undefined) {
//     console.log("hello")

//     let writtenExpose = slide.entries[selectedSlide];

//     return (
//       <>
//         <Navbar state={state} control={control} slide={slide}/>

//         <div
//           className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
//           style={{ maxWidth: "25rem" }}
//         >
//           <div className=" flex-column w-100">
//             <h3>{writtenExpose.slideTitle}</h3>
//             <br />
//             <p>{writtenExpose.slideDesc}</p>


//             <button
//               type="submit"
//               className="btn btn-block border  text-center btn-lg mt-4"
//               onClick={() => {
//                 setText(state.writtenExposure[selectedSlide])
//                 setGoIn(true);
//                 // setCheckSuds (true);
//               }}
//             >
//               Next
//             </button>

//             <EmergencyResources />
//           </div>
//         </div>
//       </>
//     );
//   }

//   return (
//     <>
//       <Navbar state={state} control={control} slide={slide}/>

//       <div
//         className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
//         style={{ maxWidth: "25rem" }}
//       >

//         <div className=" flex-column w-100">
//           <h3>{slide.title}</h3>
//           <br />

//           {slide.entries.map((e, index) => {
//             if (index <= state.writtenExposure.length) {
//               return (
//                 console.log("end"),
//                 <div
//                   onClick={(e) => {
//                     setSelectedSlide(index);
//                   }}
//                   className="btn btn-block border text-center btn-lg "
//                 >
//                   {e.title}
//                 </div>
//               );
//             } else {
//               return <></>;
//             }
//           })}

//           <button
//             type="submit"
//             className="btn btn-block border  text-center btn-lg mt-4"
//             onClick={() => {
//               control.goSlide(slide.complete);
//             }}
//           >
//             Back to Savyn
//           </button>

//           <EmergencyResources />
//         </div>
//       </div>
//     </>
//   );
// };
// export default JournalEntries;
import EmergencyResources from "../Components/EmergencyResources";
import Navbar from "../Components/Navbar";
import { useState } from "react";
import SetSUDS from "../Templates/SetSUDS";

import {
    InputGroup,
    FormControl,
  } from "react-bootstrap";
const JournalEntries = ({ state, control, slide }) => {
  let [selectedSlide, setSelectedSlide] = useState(undefined);
  let [goIn, setGoIn] = useState(false);
  let [checkSuds, setCheckSuds] = useState(false);
  let [text, setText] = useState("")

  if (checkSuds) {
    console.log("hi there")

    let writtenExpose = slide.entries[selectedSlide];

    return (
      <>
        {/* <Navbar state={state} control={control} slide={slide}/> */}

        {/* <div
          className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
          style={{ maxWidth: "25rem" }}
        > */}
          <SetSUDS state={state} control={control} slide={slide} />            
          <button 
              type="button" 
              className="btn btn-block border text-center btn-lg mb-2"
              onClick={()=>{
                // setText(state.writtenExposure[selectedSlide])
                setCheckSuds(false);
                // setSelectedSlide(undefined);
                setGoIn(true);
              }}>Next</button>

          <div className=" flex-column w-100">
            {/* <h3>{writtenExpose.slideTitle}</h3>
            <br />
            <p>{writtenExpose.slideDesc}</p> */}


            {/* <button
              type="submit"
              className="btn btn-block border  text-center btn-lg mt-4"
              onClick={() => {
                setText(state.writtenExposure[selectedSlide])
                setCheckSuds(false);
              }}
            >
              Next
            </button> */}

            <EmergencyResources />
          </div>
        {/* </div> */}
      </>
    );
  }
  if (goIn){
    console.log("here")
    let writtenExpose = slide.entries[selectedSlide];

    return (
        <>

          <Navbar state={state} control={control} slide={slide}/>
    
          <div
            className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
            style={{ maxWidth: "25rem" }}
          >
            <div className=" flex-column w-100">
            

          

              <h3>{writtenExpose.slideWrite}</h3>

              <InputGroup>
                <FormControl as="textarea" aria-label="With textarea" onChange={(e)=>{
setText(e.target.value);
                }} value={text}/>
              </InputGroup>

  
              <button
                type="submit"
                className="btn btn-block border  text-center btn-lg mt-4"
                onClick={() => {
                    control.addWrittenExposure(text,selectedSlide)
                    setGoIn(false);
                    setSelectedSlide(undefined);
                    setText("");
                }}
              >
                Save Doc
              </button>
  
              <EmergencyResources />
            </div>
          </div>
        </>
      );

  }

  if (selectedSlide !== undefined) {
    // console.log("hello")

    let writtenExpose = slide.entries[selectedSlide];

    return (
      <>
        <Navbar state={state} control={control} slide={slide}/>

        <div
          className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
          style={{ maxWidth: "25rem" }}
        >
          <div className=" flex-column w-100">
            <h3>{writtenExpose.slideTitle}</h3>
            <br />
            <p>{writtenExpose.slideDesc}</p>

            <button
              type="submit"
              className="btn btn-block border  text-center btn-lg mt-4"
              onClick={() => {
                setText(state.writtenExposure[selectedSlide])
                console.log (selectedSlide)
                setGoIn(true);
                // setCheckSuds (true);
              }}
            >
              Next
            </button>

            <EmergencyResources />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>

      <div
        className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
        style={{ maxWidth: "25rem" }}
      >

        <div className=" flex-column w-100">
          <h3>{slide.title}</h3>
          <br />
                {
                  state.wetSessions.map((e, index)=>{
                    // if (index <= state.writtenExposure.length) {
                      return <div key= {"button:"+index} onClick={
                          ()=>{
                            control.setWETSession(e.index)
                          }
                      }
                        className="btn border border-secondary w-100 mb-2 py-3 text-left"
                      >
                        {e.index}
                      </div>
                    // }
                    // else{
                    //   return<></>;
                    // }
                  })
              
            }


<div onClick={
                        (e)=>{
                            control.goWhere('homePage')
                        }
                    }
                      className="btn border border-secondary w-100 mb-2 py-3 text-left"
                    >
                      Go Homepage
                    </div>




        
          </div>
          <EmergencyResources />
        </div>
      {/* </div> */}
    </>
  );
};
export default JournalEntries;


