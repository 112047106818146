import withoutShowdow from "../Images/without_shadow.png";
import { useState } from "react";
import "@fontsource/open-sans";

import {Navbar, NavDropdown, Nav, Form} from 'react-bootstrap';

const _Navbar = ({ state, control, slide }) => {

  return <Navbar className="d-lg-none" bg="white" expand="false">
  <Navbar.Brand style={{cursor:"pointer"}}tabindex={41} onClick={()=>{
        control.goWhere('homePage')
      }}>
  <img tabindex={42}
        alt=""
        src={withoutShowdow}
        width="40"
        height="40"
        className="d-inline-block align-top"
      />{' '}<h1 style={{fontSize:'30px'}}  className="d-inline-block align-top">SAVYN</h1>
  </Navbar.Brand>
  
 
  <Navbar.Toggle tabindex={43} aria-controls="basic-navbar-nav" className="" />
  <Navbar.Collapse tabindex={44} id="basic-navbar-nav">
    <Nav tabindex={45} className="mr-auto">
      
      <Nav.Link tabindex={46}  onClick={()=>{
        control.goWhere('homePage')
      }} >Home</Nav.Link> 
        <Nav.Link tabindex={47} onClick={()=>{
        control.goWhere('breathExercise')
      }} >Square breathing</Nav.Link> 
        <Nav.Link tabindex={48} onClick={()=>{
        control.goWhere('calmPlace')
      }}>Visit a calm place</Nav.Link> 
       <Nav.Link tabindex={49} onClick={()=>{
        control.goWhere('container')
      }}>Put your thoughts away in containers</Nav.Link> 
       <Nav.Link tabindex={50} onClick={()=>{
        control.goWhere('gratitude')
      }}>Gratitude journal</Nav.Link> 
       <Nav.Link tabindex={51} onClick={()=>{
        control.goWhere('traumaTriggers')
      }} >Trauma and triggers</Nav.Link> 
      <Nav.Link tabindex={52} onClick={()=>{
        control.goWhere('breathExerciseArabic')
      }} >التنفس التأمل </Nav.Link> 
       <Nav.Link tabindex={53}onClick={()=>{
        control.goWhere('breathExerciseMandarin')
      }} >盒子呼吸法 </Nav.Link> 
        <Nav.Link tabindex={54} onClick={()=>{
        control.goWhere('about')
      }}>About</Nav.Link> 
      <Nav.Link tabindex={55} onClick={()=>{
        control.goWhere('advisors')
      }}>Advisors</Nav.Link> 
              <Nav.Link tabindex={56} onClick={()=>{
        control.goWhere('feedbackForm')
      }}>Feedback</Nav.Link>
      <Nav.Link tabindex={57} onClick={()=>{
        control.goWhere('signUp')
      }}>Sign up</Nav.Link>
      <Nav.Link tabindex={58} onClick={()=>{
        control.goWhere('terms')
      }}>Terms</Nav.Link>

    </Nav>
  </Navbar.Collapse>
</Navbar>

};
export default _Navbar;
