import State from './State';

import AudioPlay from "../Templates/AudioPlaySingle";
import AudioPlayOnly from "../Templates/AudioPlayOnly";
import WriteEntry from "../Templates/WriteEntry";
import RecordAudioEmdr from "../Templates/RecordAudioEmdr";
import HomePage from "../Templates/Homepage";
import Assessment from "../Templates/Assessment";
import BDIAssessment from "../Templates/BDIAssessment";
import TrumaCreator from "../Templates/TrumaCreator";
import Counter from "../Templates/Counter";
import Video from "../Templates/Video";
import ScaleSlide from "../Templates/ScaleSlide";
import GetName from "../Templates/GetName";
import SignUp from "../Templates/SignUp";
import EMDRSelector from "../Templates/EMDRSelector";
import EMDRJournal from "../Templates/EMDRJournal";
import TestTemplate from "../Templates/TestTemplate";
import PatientDES from "../Templates/PatientDES";
import PatientChecklist from "../Templates/PatientChecklist";
import SelectMood from "../Templates/SelectMood";
import SetCalm from "../Templates/SetCalm";
import YesNo from "../Templates/YesNo";
import SetSUDS from "../Templates/SetSUDS";
import VisitSafespace from "../Templates/VisitSafespace";
import Desc from "../Templates/Desc";
import MultiButton from "../Templates/MultiButton";
import MultiSelect from "../Templates/MultiSelect";
import MultiAudio from "../Templates/MultiAudio";
import JournalEntries from "../Templates/JournalEntries";
import PatientHistory from "../Templates/PatientHistory";
import Consent from "../Templates/Consent";
import WriteContainer from "../Templates/WriteContainer";
import WriteWet from "../Templates/WriteWet";
import VisitContainer from "../Templates/VisitContainer";
import Feedback from "../Templates/Feedback";
import BreathingExercises from '../Templates/BreathingExercises';
import Terms from "../Components/Terms";
import Advisors from "../Templates/Advisors";
import { useLocation } from 'react-router-dom'


function DetermineIfFirstTime({ state, control, goFinish, where }) {
    if (goFinish) {
      state.bookMark["emdrReturn"] = goFinish;
    }
  
    if (state.notFirstTime) {
      return (
        <VisitSafespace
          state={state}
          control={control}
          slide={where["createSafeSpace"]}
        />
      );
    } else {
      return (
        <YesNo state={state} control={control} slide={where["haveSafeSpace"]} />
      );
    }
  }


function InnerApp({  state,  slide,  where, control }) {
    


    let createMap = {};
    

     const location = useLocation();
    
     for (let index in where){

       let object = where[index];

       if (object.url){
         createMap[object.url]=object;
       }

     }

    

    if (createMap[location.pathname]){
      slide = createMap[location.pathname];
      console.log(slide);
    }
    const displayTemplate = slide.type;

    
    if (displayTemplate === "wetChoose") {

      if (state.bdiAssessment===0) {
        return (
          <Assessment
            state={state}
            control={control}
            slide={where["wetAssessment"]}
          />
        );
      }
      // if (!state.consent) {
      //   return (
      //     <AudioPlay
      //       state={state}
      //       control={control}
      //       slide={where["historyIntro"]}
      //     />
      //   );
      // }


      if (slide) {
        return (
          <MultiButton
            state={state}
            control={control}
            slide={where["wet_list"]}
          />
        );
      }

}
  
    if (displayTemplate === "emdrChoose") {
      if (!state.consent) {
        return (
          <AudioPlay
            state={state}
            control={control}
            slide={where["historyIntro"]}
          />
        );
        
      }
  
      if (!state.entries.length && !state.audio.length) {
        return (
          <DetermineIfFirstTime
            state={state}
            control={control}
            goFinish="emdrChoose"
            where={where}
          />
        );
      }
  
      if (!state.containers.length) {
        return (
          <Desc state={state} control={control} slide={where["containerIntro"]} />
        );
      }
  
      if (!state.emdrSessions.length) {
        return (
          <TrumaCreator
            state={state}
            control={control}
            slide={where["traumaCreator"]}
          />
        );
      }
      if (state.emdrSessions.length === 1) {
        return (
          <MultiButton
            state={state}
            control={control}
            slide={where["emdr_list"]}
          />
        );
      }
  
      if (state.emdrSessions.length > 1 && !state.EMDRSession) {
        return (
          <EMDRSelector
            state={state}
            control={control}
            slide={where["emdr_select"]}
          />
        );
      }
  
      if (state.EMDRSession) {
        return (
          <MultiButton
            state={state}
            control={control}
            slide={where["emdr_list"]}
          />
        );
      }
    }
  
    if (displayTemplate === "YesNo") {
      return <YesNo state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "yesNo") {
      return <YesNo state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "writeContainer") {
      return <WriteContainer state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "writeDown") {
      return <WriteEntry state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "recordAudioEmdr") {
      return <RecordAudioEmdr state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "audioPlay") {
      return <AudioPlay state={state} control={control} slide={slide} key={slide?.audio} />;
    
    } else if (displayTemplate === "audioPlayOnly") {
      return <AudioPlayOnly state={state} control={control} slide={slide} key={slide?.audio} />;
    
    } else if (displayTemplate === "desc") {
      return <Desc state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "visitSafeSpace") {
      return (
        <DetermineIfFirstTime state={state} control={control} slide={slide} where={where}/>
      );
    } else if (displayTemplate === "multiSelect") {
      return <MultiSelect state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "multiButton") {
      return <MultiButton state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "selectMood") {
      return <SelectMood state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "setCalm") {
      return <SetCalm state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "setSUDS") {
      return <SetSUDS state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "homePage") {
      return <HomePage state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "multiAudio") {
      return <MultiAudio state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "journalEntries") {
      return <JournalEntries state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "writeWet") {
        return <WriteWet state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "assessment") {
      return <Assessment state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "bdiAssessment") {
      return <BDIAssessment state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "patientHistory") {
      return <PatientHistory state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "consetForm") {
      return <Consent state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "counter") {
      return <Counter state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "video") {
      return <Video state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "scale") {
      return <ScaleSlide state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "traumaCreator") {
      return <TrumaCreator state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "EMDRJournal") {
      return <EMDRJournal state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "testTemplate") {
      return <TestTemplate state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "journalEntriesEMDR") {
      return <EMDRJournal state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "patientDES") {
      return <PatientDES state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "patientChecklist") {
      return <PatientChecklist state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "visitContainer") {
      return <VisitContainer state={state} control={control} slide={slide} />;
    }else if (displayTemplate === "feedbackForm") {
      return <Feedback state={state} control={control} slide={slide} />;
    }else if (displayTemplate === "signUp") {
      return <SignUp state={state} control={control} slide={slide} />;
    }else if (displayTemplate == "breathingExercises") {
    return <BreathingExercises state={state} control={control} slide={slide} />;
    } else if (displayTemplate == "terms") {
      return <Terms state={state} control={control} slide={slide} />;
      }else if (displayTemplate == "advisors") {
        return <Advisors state={state} control={control} slide={slide} />;
        }
 
  }



  export default InnerApp;