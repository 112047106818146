
import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';


import { useState, useEffect } from "react";

const VisitSafespace = ({ state, control, slide }) => {


  const [selectEntry, setSelectEntry] = useState(undefined);
  const [selectAudio, setSelectAudio] = useState(undefined);

  if  (!state.audio){
    state.audio = [];
  }
  if (!state.entries){
    state.entries = [];
  }


  if (selectEntry !== undefined){


    return <>
    <Navbar state={state} control={control} slide={slide}/>

    <div className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 " style={{ maxWidth: "25rem" }}>
      <div className=" flex-column w-100">

        <h3 className="text-center my-4">Text Entry</h3><br/>

        <textarea onChange={(e)=>{
          
        }} className="form-control border border-secondary mt-n3" name="" rows="12" value={state.entries[selectEntry].entry} readOnly={true}></textarea>
           
        <button
          type="button"
          className="btn btn-block border  text-center btn-lg mt-4"
          onClick={()=>{
            setSelectAudio(undefined);
            setSelectEntry(undefined);
          }}
        >
          Back
        </button>
       


        <EmergencyResources />
      </div>
    </div>
  </>

  }
  if (selectAudio !== undefined){

    let audioURL = state.audio[selectAudio];
    return <>
    <Navbar state={state} control={control} slide={slide}/>


    <div
      className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
      style={{
        maxWidth: "25rem"
      }}
    >
      <div className=" flex-column w-100">
        <h3 className="mb-4">Calm Space Audio Entry</h3>
        <p className="mb-4"></p>



        <div className="m-5">
          <audio controls>
          <source src={audioURL} type="audio/mpeg"/>

            Your browser does not support the audio tag.
                    </audio>
        </div>

        <button
          type="button"
          className="btn btn-block border  text-center btn-lg mt-4 "
        
          onClick={() => {
            setSelectAudio(undefined);
                  setSelectEntry(undefined);
          }}
        >
          Go Back
        </button>
        <EmergencyResources />
      </div>
    </div>




  </>

  }


  return (
    <>

      <Navbar state={state} control={control} slide={slide}/>

      <div className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 " style={{ maxWidth: "25rem" }}>
        <div className=" flex-column w-100">

          <h3>Calm Space Library</h3><br />

            {
              state.audio.map((e, index)=>{
                return <div key={"audio"+index} className="btn btn-block  text-left btn-lg" onClick={()=>{

                  setSelectAudio(index);
                  setSelectEntry(undefined);

                }}>


                <i className="fas fa-book-open mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}></i>
  
                  Calm Space recording {index+1}
              </div>
              })
            }


            {
              state.entries.map((e, index)=>{
                return <div key={"entries"+index} className="btn btn-block  text-left btn-lg" onClick={()=>{
                  setSelectAudio(undefined);
                  setSelectEntry(index);
                  console.log(index);
                }}>


                <i className="fas fa-book-open mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}></i>
  
                {e.title}
              </div>
              })
            }

          <button 
          type="submit" 
          className="btn btn-block border  text-center btn-lg mt-4"
          onClick = {()=>{
            control.goWhere("modifySafeSpace");
            }}
          >
            Add a Calm Space
            </button>


            <button 
          type="submit" 
          className="btn btn-block border  text-center btn-lg mt-4"
          onClick = {()=>{
            if(state.bookMark["emdrReturn"]){
              control.goWhere(state.bookMark["emdrReturn"])
            } else {
              control.goWhere("howSafeDoYouFeel")
            }
          }}
          >
            {state.bookMark["emdrReturn"]?"Next":"Back to Savyn"}
            
            </button>
            


         


          <EmergencyResources />
        </div>
      </div>







    </>
  );
};
export default VisitSafespace;
