import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';
import Sidepanel from '../Components/Sidepanel';
import {
    Container,
    Form,
    Col,
    Row,
    Table,
    InputGroup,
    FormControl,
  } from "react-bootstrap";
import { BsArrowCounterclockwise, BsArrowClockwise } from "react-icons/bs";
import { IoPlayCircle, IoPauseCircle, IoCloseCircleOutline } from "react-icons/io5";




const BreathingExercises = ({ state, control, slide }) => {

    function playAudio() {
        const engAudio = document.getElementById("myAudio");
        const play = document.getElementById("playBtn");
        const pause = document.getElementById("pauseBtn");
      
        if (engAudio.paused) {
          engAudio.play();
          play.style = 'display: none';
          pause.style = 'display: block';
        } else {
          engAudio.pause();
          play.style = 'display: block';
          pause.style = 'display: none';
        }
    }

    const fwdAudio = () => {
      document.getElementById("myAudio").currentTime = document.getElementById("myAudio").currentTime + 15;
    }
    
    const rwdAudio = () => {
      document.getElementById("myAudio").currentTime = document.getElementById("myAudio").currentTime - 15;
    }
  return (
    <>
 <Navbar state={state} control={control} slide={slide}/>
      
      <Row className="bg-img min-vh-100" >
       
       <Sidepanel state={state} control={control} slide={slide}/>
       
         <div className="box">

         <div
        className="  container d-flex  justify-content-center  mh-100 "
        style={{
          maxWidth: "35rem"
        }}>
          <div className=" flex-column w-100">


            <div className="row justify-content-center my-4">
            <div className="row justify-content-center my-4">
            <button 
              className="col-2 btn my-auto text-left controlSeconds"
              onClick={() => rwdAudio()}
            >
              <BsArrowCounterclockwise className="float-right audioControlBtn" />
              <small><b>15</b></small>
            </button>

            <button 
              className="col-4 btn my-auto mx-3 text-center"
              onClick={() => playAudio()}
            >
           
           <div id="playBtn" className="my-auto">
                <IoPlayCircle className="playAudioBtn" />
              </div>
              <div id="pauseBtn" className="my-auto" style={{display: "none"}}>
                <IoPauseCircle className="playAudioBtn"/>
              </div>  
              
            </button>

            <button 
              className="col-2 btn my-auto text-right controlSeconds"
              onClick={() => fwdAudio()}
            >
              <BsArrowClockwise className="float-left audioControlBtn" />
              <small><b>15</b></small>
            </button>
          </div>

          <div className="text-center">
          <div style={{maxWidth:'300px', margin:'auto'}}>
            <audio id="myAudio" controls controlsList="nodownload">
            <source src={slide.audio} type="audio/mpeg"/>

              Your browser does not support the audio tag.
                      </audio>
          </div>
 
          </div>

        </div>
      </div>
      </div>
      </div>
      </Row>
    </>
  );
};

export default BreathingExercises;