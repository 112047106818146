import EmergencyResources from "../Components/EmergencyResources";
import Navbar from "../Components/Navbar";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import { useState } from "react";





const SignUp = ({ state, control, slide }) => {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [subscription, setSubscription] = useState("");
  let [terms, setTerms] = useState("");


  let [showThisBox, setShowThisBox] = useState(localStorage.getItem("AgreedToTermsOfService")?false:true);
  let [expandTerms, setExpandTerms] = useState("none");


  const changeDisplay = ()=>{

    if (expandTerms==="none"){
      setExpandTerms("block");
    } else {
      setExpandTerms("none");
      if (!showThisBox){
        return <></>
      }}}
      // if (!showThisBox){
      //   return <></>
      // }
  return (
    <>
      {/* <Navbar state={state} control={control} slide={slide}/> */}
      <Row className="signUp min-vh-100" >
       
      
       
         <div className="box">
         <div
        className="  container d-flex  justify-content-center  mh-100 "
        style={{
          maxWidth: "35rem"
        }}>
          <div className=" flex-column w-100">
          <h1 className = "mb-5">
Sign Up</h1>

          <Form>
            <Form.Group controlId="userName">
              <Form.Control className="mt-4" type="text" placeholder="Preferred Name" onChange={(e)=>{
                setName(e.target.value);
              }} value={name}/>
                <Form.Control type="email" placeholder="Email" onChange={(e)=>{
                setEmail(e.target.value);
              }} value={email}/>
              <Form.Check type="checkbox" label="Please send me marketing emails from Savyn." style={{textAlign: "left"}} 
              onChange={(e)=>{
                setSubscription(!subscription);
               }} value={subscription}/>
              <br />
              <div style={{textAlign: "left"}}>
                <Form.Check className="d-inline" type="checkbox" label="" onChange={(e)=>{
                setTerms(!terms);
               }} value={terms} />
                <p className="mt-5 mb-2 d-inline">By accessing this website, you are agreeing to these <a style={{cursor:"pointer",textDecoration:"underline"}} onClick={changeDisplay}>Terms of Use</a>. Therefore, we suggest that you review them carefully.</p>
              </div>
       
            </Form.Group>
            <div id="popUp" className="mt-5 text-left" style={{fontSize:"9px", display:expandTerms}}>
<h2  style={{fontSize:"20px"}}className="mb-4">SAVYN TECH INC’s WEBSITE TERMS OF USE</h2>

<p className="mb-2">Savyn Tech Inc’s (“Company”) maintains the Company website as a service to the Internet community. </p>
<p className="mb-2">The Company website (the “Website”) has been designed to provide general information about the Company products and services and the services of its affiliated companies. </p>
<p className="mb-2">Review these and Terms of Use carefully before using the Website. By using and or visiting the Website, you signify your assent to both these Terms and the Company Privacy Statement. If you do not agree to these terms, do not use this website. </p>

<h2 className="my-3" style={{fontSize:"20px"}}>Terms of Use </h2>

<p className="mb-2">The following terms of use (the "Terms of Use") govern your use (the term "use" will mean access or use, as applicable). The term "you" refers to the person or entity visiting the Platform, browsing or otherwise using the Platform.</p>
<p className="mb-2">BY ACCESSING AND USING THE PLATFORM, YOU ACCEPT AND AGREE TO BE BOUND BY AND COMPLY WITH THESE TERMS OF USE. IF YOU DO NOT ACCEPT AND AGREE TO BE BOUND BY THESE TERMS OF USE, PLEASE DO NOT ACCESS OR USE THE PLATFORM. </p>
<p className="mb-2">DO NOT USE THE PLATFORM FOR MEDICAL EMERGENCIES OR IF YOU ARE HAVING SUICIDAL THOUGHTS. IF YOU HAVE A MEDICAL EMERGENCY OR ARE CONTEMPLATING HARMING YOURSELF OR OTHERS, YOU SHOULD DISCONTINUE USING THE PLATFORM AND CALL 911 IMMEDIATELY OR ATTEND THE EMERGENCY ROOM OF THE NEAREST HOSPITAL OR HEALTH CENTRE. UNDER NO CIRCUMSTANCES SHOULD YOU ATTEMPT SELF-TREATMENT BASED ON ANY CONTENT (AS HEREAFTER DEFINED). </p>
<p className="mb-2">AS A CONDITION OF YOUR USE OF THE PLATFORM, YOU WARRANT THAT: (1) YOU HAVE REACHED THE AGE OF MAJORITY IN YOUR JURISDICTION OF RESIDENCE; (2) YOU POSSESS THE LEGAL AUTHORITY TO CREATE A BINDING LEGAL OBLIGATION; (3) YOU WILL USE THE PLATFORM IN ACCORDANCE WITH THESE TERMS OF USE; (4) ALL INFORMATION SUPPLIED BY YOU ON THE PLATFORM IS TRUE, ACCURATE, CURRENT AND COMPLETE; AND (5) IF YOU ARE ACCESSING OR USING THE PLATFORM ON BEHALF OF ANOTHER PERSON OR A CORPORATE ENTITY OR OTHER NON-INDIVIDUAL, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND SUCH PERSON OR ENTITY TO THESE TERMS OF USE.</p>

 


<h2  className="my-3" style={{fontSize:"20px"}}>SCOPE OF TERMS </h2>
 <p className="mb-2">These Terms of Use are applicable to the Website. The Terms of Use also apply to all resources and materials provided through the Website whether hosted by Company or a third party. </p>
 <p className="mb-2">Company, and its affiliated companies, may provide specific Terms of Use, Privacy Statements, and Notices for other software, applications, and services. When you use the Company mobile application, coaching services, or medical services, use of such software and services is governed by the specific Terms of Use for those services.</p>
 <h2  className="my-3" style={{fontSize:"20px"}}>USE TERMS  </h2>
 <p className="mb-2">As a condition of your use of the Website, you will not use the Website for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You are prohibited from violating, or attempting to violate the security or availability of this Website. </p>
 <p className="mb-2">In using this Website, you agree not to:</p>
 <p className="mb-2">circumvent or attempt to circumvent any security measures of the Website;</p>
 <p className="mb-2">attempt to obtain personal information, or collect information about users of the Website;</p>
 <p className="mb-2">reverse engineer or otherwise attempting to discover the source code of any portion of the Website; </p>
 <p className="mb-2">interfere with the operation of the Website or any user’s enjoyment of the Website, including by: (a) uploading or otherwise any disseminating virus, adware, spyware, worm, or other malicious code; (b) interfering with or disrupting any network, equipment, or server; </p>

 
 <p className="mb-2">violate, or encourage others to violate, any right of a third party, including by infringing or misappropriating any third party intellectual property right;  </p>
 <p className="mb-2">post, upload, or distribute any content that is unlawful, defamatory, libelous, inaccurate, or that a reasonable person could deem to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate;  </p>
 <p className="mb-2">manipulate or otherwise display the Website by using framing, mirroring or similar navigational technology; or U violate any applicable laws or regulations or these Terms of Use</p>
 <h2  className="my-3" style={{fontSize:"20px"}}>PRIVACY AND PERSONAL INFORMATION</h2> 
 <p className="mb-2">Company’s Website Privacy Statement (the “Privacy Statement”), as it may change from time to time, is a part of these Terms of Use and is incorporated herein by this reference. Company reserves the right to use and disclose your information in the manner consistent with the Privacy Statement.  </p>
 <p className="mb-2">No Medical Advice </p>
 <h2  className="my-3" style={{fontSize:"20px"}}>YOU AGREE THAT: </h2>
 <p className="mb-2">(i) IF YOU ARE IN NEED OF OR ARE SEEKING MEDICAL TREATMENT OR EMERGENCY CARE, YOU SHOULD CONTACT YOUR HEALTHCARE PROVIDER OR CALL EMERGENCY SERVICES IMMEDIATELY; </p>
 <p className="mb-2">(ii) COMPANY DOES NOT PROVIDE MEDICAL TREATMENT, ADVICE, OR DIAGNOSIS AND IS NOT A HEALTHCARE PROVIDER; </p>
 <p className="mb-2">(iii) COMPANY IS NOT YOUR HEALTHCARE PROVIDER, IS NOT AND DOES NOT PROVIDE EMERGENCY SERVICES, AND MAY NOT CONTACT YOU OR ANYONE ON YOUR BEHALF WITH RESPECT TO YOUR MEDICAL CONDITION OR TREATMENT;</p>
 <p className="mb-2">(iv) DATA PROVIDED BY COMPANY THROUGH THE WEBSITE IS FOR INFORMATIONAL PURPOSES ONLY AND IS NOT INTENDED TO REPLACE THE RELATIONSHIP BETWEEN YOU AND YOUR PHYSICIAN OR OTHER HEALTHCARE PROVIDER; </p>
 <p className="mb-2">(v) COMPANY IS NOT A LICENSED MEDICAL CARE PROVIDER AND HAS NO EXPERTISE IN DIAGNOSING, EXAMINING, OR TREATING MEDICAL CONDITIONS OF ANY KIND, OR IN DETERMINING THE EFFECT OF ANY SPECIFIC TREATMENT ON A MEDICAL CONDITION; (vi) YOU SHOULD ALWAYS CONSULT A PHYSICIAN IF YOU HAVE ANY QUESTIONS REGARDING A MEDICAL CONDITION; </p>
 <p className="mb-2">(vii) NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ OR RECEIVED USING THE WEBSITE;</p>
 <p className="mb-2">(viii) COMPANY IS NOT RESPONSIBLE FOR THE ACCURACY, RELIABILITY, EFFECTIVENESS, OR CORRECT USE OF INFORMATION YOU RECEIVE THROUGH OR IS GENERATED BY THE WEBSITE.</p>
 <h2  className="my-3" style={{fontSize:"20px"}}>Disclaimers </h2>
 <p className="mb-2"> ALL CONTENT ON THIS WEBSITE IS PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY AND NON-INFRINGEMENT. </p>
 <p className="mb-2">Company makes no warranty as to the accuracy, completeness, currency or reliability of any content available through this Website. You are responsible for verifying any information before relying on it. Use of the Website and the content available on the Website is at your sole risk. Company makes no representations or warranties that use of the Website will be uninterrupted or error-free. You are responsible for taking all necessary precautions to ensure that any content you obtain from the Website is free of viruses. </p>
 <p className="mb-2">Company makes no warranty as to the accuracy, completeness, currency or reliability of any content available through this Website. You are responsible for verifying any information before relying on it. Use of the Website and the content available on the Website is at your sole risk. Company makes no representations or warranties that use of the Website will be uninterrupted or error-free. You are responsible for taking all necessary precautions to ensure that any content you obtain from the Website is free of viruses. </p>
 <p className="mb-2">COMPANY SHALL NOT BE HELD RESPONSIBLE FOR ANY ACTION TAKEN THAT IS BASED ON THE INFORMATION PRESENTED ON THE COMPANY WEBSITES. COMPANY EXPRESSLY DISCLAIMS ANY LIABILITY, WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE WEBSITES, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING BUT NOT LIMITED TO RELIANCE BY ANY PARTY ON ANY CONTENT OBTAINED THROUGH THE USE OF THE WEBSITES, THE INABILITY TO USE THE WEBSITES OR ANY ERRORS OF OMISSIONS IN THE CONTENT OF THE WEBSITES. </p>
 <h2  className="my-3" style={{fontSize:"20px"}}>Termination of Use </h2>
 <p className="mb-2">If you violate any provision of these Terms of Use, your permission from us to use the Website will terminate automatically. In addition, Company may in its sole discretion terminate your access to the Website at any time, with or without notice. You may cease use of the Website at any time. </p>
 <h2  className="my-3" style={{fontSize:"20px"}}>Modification</h2> 
 <p className="mb-2">We reserve the right to modify the Website at any time (including by limiting or discontinuing certain features of the Website) without notice to you. We will have no liability whatsoever on account of any change to the Website or any suspension or termination of your access to or use of the Website. </p>
 <h2 style={{fontSize:"20px"}}>Links to Other Materials or Sites </h2>
 <p className="mb-2">To the extent Company provides information from, or links or references to, websites operated by third parties, Company does not monitor or investigate such websites and Company is not responsible for the content, functionality, or practices of such websites. Inclusion of links to third party websites does not imply approval or endorsement of the linked website by Company. If you decide to access these third party websites, you do so at your own risk. You agree that Company has no liability for any damage or loss of any type that is a result of your use of a third party website. </p>
 <h2  className="my-3" style={{fontSize:"20px"}}>Consent to Electronic Communications</h2>
 <p className="mb-2">By using the Website, you consent to receiving certain electronic communications from us. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that those communications be in writing. </p>
 <h2  className="my-3" style={{fontSize:"20px"}}>Governing Law </h2>
 <p className="mb-2">These Terms of Use shall be governed by and construed in accordance with the laws of the Ontartio of Ontario,Canadawithout regard to its conflicts of laws provision. The United Nations Convention on Contracts for the International Sale of Goods shall not apply. To the fullest extent permitted by applicable law, the parties shall seek resolution exclusively through binding arbitration using a single arbitrator and the rules promulgated by an arbitrator to be determined by all parties. The decision of the arbitrator shall be binding on the parties and may be entered into a court of competent jurisdiction. All such arbitration shall be conducted in Toronto. To the extent that the above mandatory arbitration clause is invalid under applicable laws, the parties hereby consent to, and hereby agree to submit to, the exclusive jurisdiction and venue in the provincial courts in Ontario or any federal court located therein. In any action, arbitration or proceeding to enforce or interpret these Terms of Use, the prevailing party will be entitled to recover the costs and expenses (including reasonable attorneys' fees) that it incurred in connection with such action, arbitration or proceeding and enforcing any judgment or order obtained. </p>
 <h2  className="my-3" style={{fontSize:"20px"}}>Amendment</h2> 
 <p className="mb-2">Company may revise these Terms of Use from time to time by updating this posting. You should visit this page from time to time to review the current terms.</p>
 <h2  className="my-3" style={{fontSize:"20px"}}>Contact Information </h2>
 <p className="mb-2">You may contact us by emailing us at info@savyntech.com. </p>
 <p className="mb-2">Last Updated: July 19, 2021</p>
</div>
            <button
               type="button"
               style={{fontSize:"16px"}}
              className="btn btn-block  text-center btn-md mb-2 signupBtn"
              onClick={() => {
                let data = {
                  sheet:"SignUp",
                  name,
                  email,
                  subscription:subscription,
                  terms:terms
                }


                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data)
                };
                
                
                 //fetch('http://localhost:8080/json', requestOptions)
                fetch('/json', requestOptions)
                  .then(response => response.json())
                  .then(data => {
                    control.goSlide(slide.complete)
                  });
                         


              }}
              
            >  Sign Up
              
            </button>
          <a onClick={()=>{
                  control.goWhere("homePage");
                } }className="mb-3" style={{textDecoration:"underline", cursor:"pointer", color:"blue", fontSize:"14px" }}>Back to SavynCares platform</a>
            
          </Form>
          <br/>
        <div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

<br/>

<div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>


        </div>
        </div>
        </div>
        
      {/* <EmergencyResources /> */}
</Row>


    </>
  );
};
export default SignUp;