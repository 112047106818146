import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';
import { Range, getTrackBackground } from 'react-range';
import { useState } from "react";


const STEP = 1;
const MIN = 0;
const MAX = 10;

const SetCalm = ({ state, control, slide }) => {

  const [values, setValues] = useState([5])
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>


      <div
        className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
        style={{
          maxWidth: "25rem"
        }}
      >
        <div className=" flex-column w-100">
          <h3 className="mb-4">How did you feel visiting the calm place?</h3>
          <p className="mb-4"></p>
          <div>
         

          <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
     
        onChange={(values) => setValues(values)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '16px',
              width: '5px',
              backgroundColor: index * STEP < values[0] ? '#548BF4' : '#ccc'
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: values,
                  colors: ['#548BF4', '#ccc'],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '42px',
              width: '42px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC'
              }}
            />
          </div>
        )}
      />
      <div style={{width:"100%", textAlign:"center"}}>
      <output style={{ marginTop: '30px' }}>{values[0].toFixed(0)}</output>
      </div>



            
                <label className="float-right mb-5">Completely Calm</label>
                <label className="float-left mb-5">Not Calm</label>
                </div>


                <button onClick={()=>{
        control.goSlide(slide.complete)
      }} type="button" className="btn btn-block border text-center btn-lg mb-2">Continue</button>
    

              <EmergencyResources />
             
             </div>
             </div>
    </>
  );
};
export default SetCalm;
