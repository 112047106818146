import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';
import Sidepanel from '../Components/Sidepanel';
import { BsArrowCounterclockwise, BsArrowClockwise } from "react-icons/bs";
import { IoPlayCircle, IoPauseCircle, IoCloseCircleOutline } from "react-icons/io5";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

const MultiAudio = ({ state, control, slide }) => {
    
  function playAudio() {
    const engAudio = document.getElementById("myAudio");
    const play = document.getElementById("playBtn");
    const pause = document.getElementById("pauseBtn");
  
    if (engAudio.paused) {
      engAudio.play();
      play.style = 'display: none';
      pause.style = 'display: block';
    } else {
      engAudio.pause();
      play.style = 'display: block';
      pause.style = 'display: none';
    }
}

const fwdAudio = () => {
  document.getElementById("myAudio").currentTime = document.getElementById("myAudio").currentTime + 15;
}

const rwdAudio = () => {
  document.getElementById("myAudio").currentTime = document.getElementById("myAudio").currentTime - 15;
}
  return (
    <>
    <Navbar state={state} control={control} slide={slide}/>
      
      <Row className="bg-img min-vh-100" >
       
       <Sidepanel state={state} control={control} slide={slide}/>
       
         <div className="box">
         <div
        className="  container d-flex  justify-content-center  mh-100 "
        style={{
          maxWidth: "35rem"
        }}>
          <div className=" flex-column w-100">
          <h1 className="mb-4"> <i style={{fontSize:"24px", cursor:"pointer"}} 
            class="fas fa-arrow-left float-left mt-2"  
            onClick={()=>{
              control.goSlide(slide.complete);
              }}></i>{slide.title}</h1>
          <p style={{ fontSize:"16px"}} className="text-left mb-4">{slide.description}</p>


         
 {/* <div style={{maxWidth:'300px', margin:'auto'}}> */}
   
{slide.audio.map((audio, index)=>{

const myAudio = "myAudio"+index;
const playBtn = "playBtn"+index;
const pauseBtn = "pauseBtn"+index;
function playAudio() {
  const engAudio = document.getElementById(myAudio);
  const play = document.getElementById(playBtn);
  const pause = document.getElementById(pauseBtn);

  if (engAudio.paused) {
    engAudio.play();
    play.style = 'display: none';
    pause.style = 'display: block';
  } else {
    engAudio.pause();
    play.style = 'display: block';
    pause.style = 'display: none';
  }
}

const fwdAudio = () => {
document.getElementById(myAudio).currentTime = document.getElementById(myAudio).currentTime + 15;
}

const rwdAudio = () => {
document.getElementById(myAudio).currentTime = document.getElementById(myAudio).currentTime - 15;
}



          return <div key={audio.audio+index}>
                          <div className="text-left">
             <h2 style={{fontSize:"18px"}} className=" mt-2 mb-2 float-left">{audio.title}</h2>
             </div>
             
             <br /><br />
             <p className=" mt-2 mb-2 float-left">Video</p>
             <video width="100%" controls>
                <source src={audio.video} type="video/mp4"></source>
                Your browser does not support the video tag.
              </video>

             <br /><br />
             <p className=" mt-2 mb-2 float-left">Audio</p>
             
             <div style={{maxWidth:'300px', margin:'auto'}}>
             <div className="row justify-content-center my-4">

            <div className="row justify-content-center align-items-center my-3">
            <button 
              className="col-2 btn my-auto text-left controlSeconds"
              onClick={() => rwdAudio()}
            >
              <BsArrowCounterclockwise className="float-right audioControlBtn" />
              <small><b>15</b></small>
            </button>

            <button 
              className="col-4 btn my-auto mx-3 text-center"
              onClick={() => playAudio()}
            >
           
           <div id={playBtn} className="my-auto">
                <IoPlayCircle className="playAudioBtn" />
              </div>
              <div id={pauseBtn} className="my-auto" style={{display: "none"}}>
                <IoPauseCircle className="playAudioBtn"/>
              </div>  
              
            </button>

            <button 
              className="col-2 btn my-auto text-right controlSeconds"
              onClick={() => fwdAudio()}
            >
              <BsArrowClockwise className="float-left audioControlBtn" />
              <small><b>15</b></small>
            </button>
          </div>
                  </div>
                  </div>
                  <div className="text-center">
          <div style={{maxWidth:'300px', margin:'auto'}}>
          
            <audio id={myAudio}  controlsList="nodownload" controls>
            <source src={audio.audio} type="audio/mpeg"/>

              Your browser does not support the audio tag.
                      </audio>
          </div>
 
          </div>

        </div>
        
})}

<h2 style={{marginTop:"160px", fontSize:"20px"}} className="text-left">{slide.infoTitle}</h2>
          <p style={{lineHeight:'17px', fontSize:"12px"}} className="text-left mb-4 "  dangerouslySetInnerHTML={{ __html:slide.info}}></p>
          <p style={{lineHeight:'17px', fontSize:"9.5px"}} className="text-left mb-4 " dangerouslySetInnerHTML={{ __html:slide.citation}}></p>
    
          <br/>
        <div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

<br/>
<div className=" text-left " style={{lineHeight:'17px',width:"100%", fontSize:"12px" }}>
If you’re in crisis, please call the Toronto Distress Line at 416-408-4357, Canada Suicide Prevention Services at 1-833-456-4566, Kids Help Line  at 1-800-668-6868 or the Gerstein Crisis Centre at 416-929-5200. You may also choose to go to your local hospital emergency room or call 911.

</div>
<div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

          </div>
          </div>
          </div>
          {/* </div>  */}

          {/* <EmergencyResources /> */}

          </Row>





    </>
  );
};
export default MultiAudio;
