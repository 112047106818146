import EmergencyResources from "../Components/EmergencyResources";
import Navbar from "../Components/Navbar";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

const checkBoxCss = { marginLeft: "20px" };

const RowHistory = ({ index, event }) => {
  return (
    <tr>
      <td>{event}</td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
        />
      </td>
    </tr>
  );
};


const RowZeroHundred = ({index})=>{

  return <>
  <Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/> 0% <span style={{marginRight:"20px"}}/>

<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/> 10% <span style={{marginRight:"20px"}}/>


<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/>20% <span style={{marginRight:"20px"}}/>


<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/>30% <span style={{marginRight:"20px"}}/>



<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/>40% <span style={{marginRight:"20px"}}/>


<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/>50% <span style={{marginRight:"20px"}}/>


<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/>60% <span style={{marginRight:"20px"}}/>



<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/>70% <span style={{marginRight:"20px"}}/>



<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/>80% <span style={{marginRight:"20px"}}/>



<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/>90% <span style={{marginRight:"20px"}}/>


<Form.Check
  inline
  name={"radio__" + index}
  label=""
  type="radio"
  id={"inline-radio__" + index}
  style={{marginRight:"0px"}}
/>100% <span style={{marginRight:"20px"}}/>

</>

}

const PatientHistory = ({ state, control, slide }) => {
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>
      <Container className="d-flex  justify-content-center vh-100 mh-100 ">
        <div className=" flex-column w-100">
     
         
          
                   
          <h3>EMDR History</h3>
          <br />
          <Form>
            <Form.Group controlId="userName">
              <Form.Label>Name:</Form.Label>
              <Form.Control type="text" placeholder="Please type your name " />
            </Form.Group>

            <Form.Group controlId="userAge">
              <Form.Label>Age:</Form.Label>
              <Form.Control type="text" placeholder="Please type your age" />
            </Form.Group>

            <Form.Group controlId="familyStatus">
              <Form.Label>Family Status:</Form.Label>
              <Form.Control as="select">
                <option>Marrried</option>
                <option>In a relationship</option>
                <option>Common Law</option>
                <option>Single</option>
                <option>Married With Children</option>
                <option>Single With Children</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="userGender">
              <Form.Label>Gender:</Form.Label>
              <Form.Control type="text" placeholder="Please type your gender" />
            </Form.Group>

            <Form.Group controlId="userMedicalHistory">
              <Form.Label>
                History Questions
                <br />
                <span>Do you have any of the following conditions?</span>
              </Form.Label>

              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Heart Condition"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Pregnant"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="High blood pressure"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Neurological brain conditions"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Epilepsy"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Eye Problems"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="History of Drug and Alcohol Abuse"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="On medication for depression"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Dissociative Disorders"
              />
            </Form.Group>

            <Form.Group controlId="userPersonal Stability">
              <Form.Label>
                Personal Stability
                <div>
                  Have you in the last 3 months undergone one of the following
                  life events?{" "}
                </div>
              </Form.Label>

              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Family Crisis"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Social Crisis"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Financial Problems"
              />
              <Form.Check
                style={checkBoxCss}
                type="checkbox"
                label="Career Problems"
              />
              <Form.Check style={checkBoxCss} type="checkbox" label="Other" />
            </Form.Group>

            <Form.Group controlId="userRelationshipStatus">
              <Form.Label>Do you live alone?</Form.Label>

              <Form.Control as="select">
                <option>Yes</option>
                <option>No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="userHomeSupport">
              <Form.Label>
                Do you have support at home in between sessions?
              </Form.Label>

              <Form.Control as="select">
                <option>Yes</option>
                <option>No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="userSubstanceUse">
              <Form.Label>Do you use any of the following:</Form.Label>
              <Form.Check style={checkBoxCss} type="checkbox" label="Alcohol" />
              <Form.Check style={checkBoxCss} type="checkbox" label="Substance"/>
              <Form.Check style={checkBoxCss} type="checkbox" label="Drugs" />
            </Form.Group>
            <Form.Group controlId="substanceInterference">
              <Form.Label>
              Have you spent more time drinking or using than you intended to?
              </Form.Label>

              <Form.Control as="select">
                <option>Yes</option>
                <option>No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="substanceInterference">
              <Form.Label>
              Have you ever neglected some of your usual responsibilities because of using alcohol or drugs?
              </Form.Label>

              <Form.Control as="select">
                <option>Yes</option>
                <option>No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="substanceInterference">
              <Form.Label>
              Have you felt you wanted or needed to cut down on your drinking or drug use in the last year?
              </Form.Label>

              <Form.Control as="select">
                <option>Yes</option>
                <option>No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="substanceInterference">
              <Form.Label>
              Has your family, a friend, or anyone else ever told you they objected to your alcohol or drug use?
              </Form.Label>

              <Form.Control as="select">
                <option>Yes</option>
                <option>No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="substanceInterference">
              <Form.Label>
              Have you found yourself thinking a lot about drinking or using?
              </Form.Label>

              <Form.Control as="select">
                <option>Yes</option>
                <option>No</option>
              </Form.Control>
            </Form.Group>

          </Form>
          <Form.Label> 
           <b>Part 1. </b>
               Listed below are a number of difficult or stressful 
               things that sometimes happen to people. For each 
               event check one or more of the boxes to the right to
               indicate that: (a) it happened to you personally; (b) 
               you witnessed it happen to someone else; (c) you 
               learned about it happening to a close family member 
               or close friend; (d) you were exposed to it as part 
               of your job (for example, paramedic, police, military, 
               or other first responder); (e) you’re not sure if it 
               fits; or (f) it doesn’t apply to you. Be sure to 
               consider your entire life (growing up as well as 
               adulthood) as you go through the list of events. 
 
              </Form.Label>
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Event</th>
                <th>Happened to me</th>
                <th>Witnessed it</th>
                <th>Learned about it</th>
                <th>Part of my job</th>
                <th>Not Sure</th>
                <th>Doesn't Apply</th>
              </tr>
            </thead>
            <tbody>
              <RowHistory
                event={
                  "Natural disaster (for example, flood, hurricane, tornado, earthquake)"
                }
                index={0}
              />
              <RowHistory event={"Fire or Explosion"} index={1} />
              <RowHistory
                event={
                  "Transportation accident (for example, car accident, boat accident, train wreck, plane crash)"
                }
                index={2}
              />
              <RowHistory
                event={
                  "Serious accident at work, home, or during recreational activity"
                }
                index={3}
              />
              <RowHistory
                event={
                  "Exposure to toxic substance (for example dangerous chemicals radiation)"
                }
                index={4}
              />
              <RowHistory
                event={
                  "Physical assault (for example, being attacked hit, slapped, kicked and beaten up)"
                }
                index={4}
              />
              <RowHistory
                event={
                  "Assault with a weapon (for example being shot, stabbed, threatened with a knife, gun bomb)"
                }
                index={5}
              />
              <RowHistory
                event={
                  "Sexual assault (rape, attempted rape, made to perform any type of sexual act through force or threat of harm)"
                }
                index={6}
              />
              <RowHistory
                event={"Other unwanted or uncomfortable sexual experience"}
                index={7}
              />
              <RowHistory
                event={
                  "Combat or exposure to a war-zone (in the military or as a civilian)"
                }
                index={8}
              />
              <RowHistory
                event={
                  "Captivity (for example being kidnapped abducted, held hostage prisoner of war)"
                }
                index={9}
              />
              <RowHistory event={"Life Threatening Illness"} index={10} />
              <RowHistory event={"Severe human suffering"} index={11} />
              <RowHistory
                event={"Sudden violent death (of someone you know)"}
                index={12}
              />
              <RowHistory
                event={"Sudden accidental death (of someone you know)"}
                index={13}
              />
              <RowHistory
                event={
                  "Serious injury, harm, or death you caused to someone else"
                }
                index={14}
              />
              <RowHistory
                event={
                  "Any other very stressful event or experience. Briefly identify the event you were thinking of."
                }
                index={15}
              />
            </tbody>
          </Table>
          <Form>
            <Form.Group controlId="userIncidentDescription">
              <Form.Label>
                <b>Part 2. </b>
                If you have experienced more than one of the events in the
                Part 1, think about the event you consider the worst event,
                which for this questionnaire means the event that currently
                bothers you the most. If you have experienced only one of the
                events in Part 1, use that one as the worst event. Please
                answer the following questions about the worst event (check all
                options that apply):
              </Form.Label>

              <Form.Label>
                Briefly describe the worst event (for example what happened, who
                was involved, etc.)
              </Form.Label>
              <InputGroup>
                <FormControl as="textarea" aria-label="With textarea" />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="userHowLongAgoDidItHappen">
              <Form.Label>How long ago did it happen?</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please type how long ago it happened"
              />
            </Form.Group>

            <Form.Group controlId="familyStatus">
              <Form.Label>How did you experience it?</Form.Label>
              <Form.Control as="select">
                <option>It happened to me directly</option>
                <option>I witnessed it</option>
                <option>
                  I learned about it happening to a close family member or
                  friend
                </option>
                <option>
                  I was repeatedly exposed to details about it as part of my job
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="familyStatus">
              <Form.Label>Was someone's life in danger</Form.Label>
              <Form.Control as="select">
                <option>No</option>
                <option>Yes, my life</option>
                <option>Yes, someone else's life.</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="familyStatus">
              <Form.Label>Was someone injured?</Form.Label>
              <Form.Control as="select">
                <option>No</option>
                <option>Yes, I was seriously injured</option>
                <option>Yes, someone was seriously injured or killed.</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="familyStatus">
              <Form.Label>Did it involve sexual violence?</Form.Label>
              <Form.Control as="select">
                <option>No</option>
                <option>Yes</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="familyStatus">
              <Form.Label>
                If the event involved the death of a close family member, or
                close friend, was it due to some kind of accident or violence or
                was it due to natural causes?
              </Form.Label>
              <Form.Control as="select">
                <option>A death was not involved</option>
                <option>Accident or violence</option>
                <option>Natural causes</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="userHowLongAgoDidItHappen">
              <Form.Label>
                How many times have you experienced a similar event as stressful
                or nearly as stressful as the worst event?
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Please type how many times this event has happened"
              />
            </Form.Group>

            <Form.Group controlId="familyStatus">
              <Form.Label>Have you engaged in other therapy?</Form.Label>
              <Form.Control as="select">
                <option>No</option>
                <option>Cognitive behavioural therapy</option>
                <option>Inter-personal therapy</option>
                <option>Exposure therapy</option>
                <option>Other Therapy</option>
              </Form.Control>
            </Form.Group>

            <button
              onClick={() => {
                control.goSlide(slide.complete)
              }}
              type="button"
              className="btn btn-block border text-center btn-lg mb-2"
            >
               {slide.complete.title}
            </button>
            <div style={{paddingTop:"50px"}}></div>
          </Form>
        </div>
        
      </Container>
      <EmergencyResources />
    </>
  );
};

export default PatientHistory;
