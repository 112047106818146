
import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';
import React from 'react';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';


const Completionist = () => <>Next</>;

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <>{(seconds===0)?60:seconds}</>;
  }
};

const Desc = ({ state, control, slide }) => {

  return (
    <>

      <Navbar state={state} control={control} slide={slide}/>

      <div className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 " style={{ maxWidth: "25rem" }}>
        <div className=" flex-column w-100">

          <h3 className="text-center my-4">{slide.title}</h3><br/>
          <p className="mb-4">{slide.description}</p>


          

          <button
            type="button"
            className="btn btn-block border  text-center btn-lg mt-4"
            onClick={()=>{
              
            control.goSlide(slide.complete);
            }}
          >
            <Countdown
    date={Date.now() + slide.time}
    renderer={renderer}
  />
          </button>
         


          <EmergencyResources />
        </div>
      </div>
    </>
  );
};





export default Desc;
