import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';
import Sidepanel from '../Components/Sidepanel';

import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";
const MultiButton = ({state, control, slide }) => {
  console.log(slide);
  return (
    <>
     <Navbar state={state} control={control} slide={slide}/>
      
      <Row className="bg-img min-vh-100" >
       
       <Sidepanel state={state} control={control} slide={slide}/>
       
         <div className="box">
         <div
        className="  container d-flex  justify-content-center  mh-100 "
        style={{
          maxWidth: "35rem"
        }}>
          <div className=" flex-column w-100">
            <h1 className="text-center mb-4">
            <i style={{fontSize:"24px", cursor:"pointer"}} 
            class="fas fa-arrow-left float-left mt-2"  
            onClick={()=>{
              control.goSlide(slide.complete);
              }}></i>{slide.title}</h1>

            <p className="text-left  mb-4" style={{fontSize:"16px"}}>{slide.description}</p>
          <a href="https://storage.googleapis.com/savyn-public/Savyn%20Care's%20Daily%20Gratitude%20Journal.docx" download style={{fontSize:"14px", maxWidth:'400px', margin:'auto', borderRadius:'30px'}}  className="mb-3 btn  border border-primary btn-block border text-center btn-lg">   Daily journal   <i class="fa fa-download" aria-hidden="true"></i></a>
           <a href="https://storage.googleapis.com/savyn-public/Savyn%20Care's%20Montly%20Gratitude%20Journal.docx" download style={{fontSize:"14px", maxWidth:'400px', margin:'auto', borderRadius:'30px'}}  className="mb-3 btn  border border-primary btn-block border text-center btn-lg">Monthly journal   <i class="fa fa-download" aria-hidden="true"></i></a>
           <h2 style={{marginTop:"160px", fontSize:"20px"}} className="text-left">{slide.infoTitle}</h2>
          <p style={{lineHeight:'17px', fontSize:"12px"}} className="text-left mb-4 "  dangerouslySetInnerHTML={{ __html:slide.info}}></p>
          <p style={{lineHeight:'17px', fontSize:"9.5px"}} className="text-left mb-4 " dangerouslySetInnerHTML={{ __html:slide.citation}}></p>
    
            {/* {slide.buttons.map((button, index)=>{
              return <div key= {"button:"+index} 
              onClick={
                (e)=>{
                  
                  control.goSlide(button)
                }
            }
            style={{maxWidth:'400px', margin:'auto', borderRadius:'30px'}}  className="mb-3 btn  border border-primary btn-block border text-center btn-lg"
            >
            
              {button.title}
              
            </div>


            })}

            {slide.writeYourOwn?<>
            
              <Form>
            <Form.Group controlId="userName">
              <Form.Label>{slide.writeYourOwn.description}</Form.Label>
              <Form.Control type="text" placeholder="Write Your Own" />
            </Form.Group>
            </Form>
            
           
            
            <div onClick={
              ()=>{
                control.goSlide(slide.writeYourOwn)
              }
          }
           className="btn border border-secondary w-100 mb-2 py-3 text-left"
          >
            {slide.writeYourOwn.title}
          </div>
          </>
            :<></>} */}
             <br/>
        <div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

<br/>
<div className=" text-left " style={{lineHeight:'17px',width:"100%", fontSize:"12px" }}>
If you’re in crisis, please call the Toronto Distress Line at 416-408-4357, Canada Suicide Prevention Services at 1-833-456-4566, Kids Help Line  at 1-800-668-6868 or the Gerstein Crisis Centre at 416-929-5200. You may also choose to go to your local hospital emergency room or call 911.

</div>
<div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

           </div>
           </div>
           </div>
           {/* <EmergencyResources/> */}
</Row>


  

    </>
  );
};
export default MultiButton;
