
import EmergencyResources from '../Components/EmergencyResources';
import withoutShowdow from "../Images/without_shadow.png";
import Navbar from '../Components/Navbar';
import Sidepanel from '../Components/Sidepanel';
import { useState } from "react";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";
const Advisors = ({ state, control, slide }) => {
  if (!slide){
    return <></>
  }
  return (
    <>
<Navbar state={state} control={control} slide={slide}/>
      
      <Row className="bg-img min-vh-100" >
       
       <Sidepanel state={state} control={control} slide={slide}/>
       
         <div className="box">
         <div
        className="  container d-flex  justify-content-center  mh-100 "
        style={{
          maxWidth: "35rem"
        }}>
          <div className=" flex-column w-100">
          <h1 className="mb-4">
          <i style={{fontSize:"24px", cursor:"pointer"}} 
            class="fas fa-arrow-left float-left mt-2"  
            onClick={()=>{
              control.goSlide(slide.complete);
              }}></i>
          {slide.title}</h1><br/>
          <p style={{lineHeight:'30px', fontSize:"16px"}} className="text-left mb-5">{slide.description}</p>


          <Table responsive="sm" style={{tableLayout:"fixed", width:"100%",borderSpacing:"10px 3rem", borderTop:"0",cellSpacing:"0", cellPadding:"0"}} >
<thead >
  <tr>
  <th > <img
  alt=""
  src="https://storage.googleapis.com/savyn-public/Naaz%20Kassem.png"
  width="130px"
  height="150px"

  className="float-left mb-2"
/>{' '} 
</th>
    <th > <img
  alt=""
  src="https://storage.googleapis.com/savyn-public/donedwards.jpeg"
  width="150px"
  height="150px"

  className="float-left mb-2"
/>{' '} 
</th>
    
  </tr>
</thead>
<tbody>
  <tr>
    <td>
<p style={{ fontSize:"16px"}} className="text-left " >Naaz Kassam, BSw, MSW, RSW
</p>
</td>
<td>
<p style={{ fontSize:"16px"}} className="text-left " >Don Edwards, Ph.D., RP</p>
</td>
</tr>
<tr>
  <td>
  <p style={{ lineHeight:"1.25", fontSize:"14px"}} className="text-left mb-5" >
Registered Psychotherapist,
<br/>
Ontario College of Social Work 
<br/>
and Ontario Association of
<br/>
Mental Health Professionals,
<br/>

Transcounselling@gmail.com
<br/>

<a href="https://www.tran-sitions.com" target="_blank" rel="noreferrer">www.tran-sitions.com</a>

<br/>
</p>
  </td>
  <td>
  <p style={{lineHeight:'1.25', fontSize:"14px"}} className="text-left mb-5" >
Registered Psychotherapist
<br/>
Therapist Certified in EMDR,
<br/>
 EMDR Consultant in Training

<br/>
Director of All of You Wellness
<br/>
 Centre

<br/>
402-65 Wellesley St East, 
<br/>
Toronto, ON, M4Y 1G7

<br/>
don@allofyou.ca
<br/>
<a href="https://www.allofyou.ca" target="_blank" rel="noreferrer">www.allofyou.ca</a>


 </p>
  </td>
</tr>
</tbody>
{/* </Table> */}
{/* <Table responsive="sm" style={{tableLayout:"fixed", width:"100%",borderSpacing:"10px 3rem", borderTop:"0",cellSpacing:"0", cellPadding:"0"}} > */}
<thead >

<th><img
  alt=""
  src="https://storage.googleapis.com/savyn-public/GulinAydin%20.jpg"
  width="140px"
  height="166px"
  className="float-left mb-2"
/>{' '} </th>
<th><img
  alt=""
  src="https://storage.googleapis.com/savyn-public/Kali%20Munro.jpeg"
  width="140px"
  height="162px"
  className="float-left mb-2"
/>{' '} </th>
  
  </thead>
  <tbody>
  <tr>
    <td><p style={{ fontSize:"16px"}} className="text-left " >Gulin Aydin, MSW, RSW</p></td>
    <td>
    <p style={{ fontSize:"16px"}} className="text-left " >Kali Munro, M.Ed., EMDR, RP</p>
    </td>
    </tr>
    <tr>
      <td> <p style={{lineHeight:'1.25', fontSize:"14px"}} className="text-left mb-5" >

EMDRIA approved EMDR 
<br/>
Consultant,
<br/>
Clinical director,
<br/> 
EMDR KW Counselling,
<br/>
gulinaydin.msw@gmail.com,
<br/>
<a href="https://www.emdrkw.com" target="_blank" rel="noreferrer">www.emdrkw.com</a>

</p>
      </td>
    <td>
      <p style={{lineHeight:'1.25', fontSize:"14px"}} className="text-left mb-5" >
Registered Psychotherapist
<br/>
717 Bloor St. West #2
<br/>
Office #1
<br/>
At Bloor and Christie
<br/>
Toronto, ON M6G 1L5
<br/>
kali@kalimunro.com
<br/>
<a href="https://www.KaliMunro.com" target="_blank" rel="noreferrer">www.KaliMunro.com</a>

 </p>

    </td>
    </tr>
   
    



</tbody>
</Table>
<br/>
        <div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

<br/>
<div tabindex={18} className=" text-left " style={{width:"100%", fontSize:"12px" }}>
If you’re in crisis, please call the Toronto Distress Line at 416-408-4357, Canada Suicide Prevention Services at 1-833-456-4566, Kids Help Line  at 1-800-668-6868 or the Gerstein Crisis Centre at 416-929-5200. You may also choose to go to your local hospital emergency room or call 911.

</div>
<div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>



          </div>
      </div>
      </div>
          {/* <EmergencyResources /> */}

</Row>
 
     </>
  );
};







export default Advisors;
