

import { useMediaQuery } from 'react-responsive'




const SignupBtn = ({ state, control, slide })=>{

  const isDesktop = useMediaQuery({
    query: '(min-width: 64em)'
  })

  if (!isDesktop){
    return <></>
  }
  
    return (
      <>
        <div
        id="myForm2"
          style={{
            position: "fixed",
            bottom: "160px",
            right: "-25px",
            border: "3px solid rgb(241, 241, 241)",
            zIndex: 9,
            transform: "rotate(-90deg)",
          }}
        >
          <div
            style={{
              maxWidth: "300px",
              padding: "0px",
              paddingBottom:"4px",
              backgroundColor: "white",
            }}
          >
            <button  tabindex={32}
            onClick={()=>{
              control.goWhere('signUp');
              }}
              style={{
                backgroundColor: "#4CAF50",
                color: "white",
                padding: "8px 12px",
                border: "none",
                cursor: "pointer",
                width: "100%",
                opacity: "0.8",
                borderRadius:"5px 5px 0 0"
              }}
>
             
         
              Sign Up
            </button>
          </div>
        </div>
      </>
      
    );



};
export default SignupBtn;
