import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';

const SelectMood = ({state, control, slide }) => {
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>


        <div
          className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
          style={{
              maxWidth:"25rem"
          }}
        >
          <div className=" flex-column w-100">
          <h3 className="text-left my-4">How are you feeling now?</h3>

      <form className="">
  
      <button onClick={()=>{
        control.goSlide(slide.complete)
      }} type="button" className="btn btn-block border text-center btn-lg mb-2">Very bad 😢</button>
    
      <button onClick={()=>{
        control.goSlide(slide.complete)
      }} type="button" className="btn btn-block border text-center btn-lg mb-2">Bad 🙁</button>
    
      <button onClick={()=>{
        control.goSlide(slide.complete)
      }} type="button" className="btn btn-block border text-center btn-lg mb-2">A little better 😑</button>
    
      <button onClick={()=>{
        control.goSlide(slide.complete)
      }} type="button" className="btn btn-block border text-center btn-lg mb-2">Better 🙂</button>

      <button onClick={()=>{
        control.goSlide(slide.complete)
      }} type="button" className="btn btn-block border text-center btn-lg mb-2">Good 😀</button>
   
      </form>
          </div>

         <EmergencyResources/>
        </div>

    </>
  );
};
export default SelectMood;
