import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';

const Video = ({ state, control, slide }) => {

  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>


      <div
        className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
        style={{
          maxWidth: "25rem"
        }}
      >
        <div className=" flex-column w-100">
          <h3 className="mb-4">{slide.title}</h3>
          <p className="mb-4">{slide.description}</p>



          <video width="100%" controls>
  <source src={slide.video} type="video/mp4"/>
  Your browser does not support the video tag.
</video>

          <button
            type="button"
            className="btn btn-block border  text-center btn-lg mt-4 "
          
            onClick={() => {
              control.goSlide(slide.complete);
            }}
          >
            {slide.complete.title}
          </button>
          <EmergencyResources />
        </div>
      </div>




    </>
  );
};
export default Video;
