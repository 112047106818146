import EmergencyResources from "../Components/EmergencyResources";
import Navbar from "../Components/Navbar";
import { useState } from "react";

import {
    InputGroup,
    FormControl,
  } from "react-bootstrap";
const BDIAssessment = ({ state, control, slide }) => {
  /*let [selectedSlide, setSelectedSlide] = useState(undefined);
  let [goIn, setGoIn] = useState(false);
  let [text, setText] = useState("")
*/
  let [question, setQuestion] = useState(0);
  let [value, setValue] = useState(0);

  if (question >= slide.questions.length){
    let currentQuestion;

    for (let key in slide.endPoint.value){

        let checkScore = parseInt(key);

        if (checkScore<=value){

            currentQuestion = slide.endPoint.value[key];
        } else {
            continue;
        }
    }


      return (
        <>
          <Navbar state={state} control={control} slide={slide}/>
    
          <div
            className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
            style={{ maxWidth: "25rem" }}
          >
            <div className=" flex-column w-100">
              <h3>{currentQuestion.title}</h3>
              <br />
              <p>{value}/63</p>
              <p>{currentQuestion.question}</p>
             
    
              {currentQuestion.answer.map((e, index) => {
                
                  return (
                    <div key={"button"+index}
                      onClick={() => {
                        control.setBDIAssessment(value);
                        setQuestion(0);
                        setValue(0);
                        
                        control.goSlide(e)
                      }}
                      className="btn btn-block border text-center btn-lg "
                    >
                      {e.title}
                    </div>
                  );
              })}
              <EmergencyResources />
            </div>
          </div>
        </>
      );
  }
  let currentQuestion = slide.questions[question];
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>

      <div
        className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
        style={{ maxWidth: "25rem" }}
      >
        <div className=" flex-column w-100">
          <h3>{currentQuestion.title}</h3>
          <br />
          <p>{currentQuestion.question}</p>
          <p>{question}/{slide.questions.length}</p>

          {currentQuestion.answer.map((e, index) => {
            
              return (
                <div key={"button"+index}
                  onClick={() => {
                    setQuestion(question+1);
                    setValue(value+e.value)
                  }}
                  className="btn btn-block border text-center btn-lg "
                >
                  {e.title}
                </div>
              );
          })}
          <EmergencyResources />
        </div>
      </div>
    </>
  );
};
export default BDIAssessment;
