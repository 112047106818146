import { useState } from "react";
import axios from "axios";
import { useQuery, useMutation } from "@apollo/client";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import gqlQuery from '../GQL';

// Create an http link:
const httpLink = createHttpLink({
  uri: "https://savyn-api.uc.r.appspot.com/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const DebugBox = ({ state, control, slide }) => {
  return (
    <ApolloProvider client={client}>
      <DebugInner state={state} control={control} slide={slide} />
    </ApolloProvider>
  );
};

const DebugInner = ({ state, control, slide }) => {
  const [currerntText, setCurrrentText] = useState("");
  const [submit, setSubmit] = useState(false);

  const [addDebug] = useMutation(gqlQuery.addDebug);

  return (
    <>
      <textarea
        valeu={currerntText}
        onChange={(e) => {
          setCurrrentText(e.target.value);
        }}
        placeholder="Type message.."
        name="msg"
        style={{
          width: "100%",
          padding: "15px",
          margin: "5px 0 22px 0",
          border: "none",
          background: "#f1f1f1",
          resize: "none",
          minHeight: "200px",
        }}
        required
      ></textarea>
      <button
        style={{
          backgroundColor: "#4CAF50",
          color: "white",
          padding: "16px 20px",
          border: "none",
          cursor: "pointer",
          width: "100%",
          marginBottom: "10px",
          opacity: "0.8",
        }}
        type="button"
        className="btn cancel"
        onClick={async () => {

            let data = await addDebug({
                variables: {
                  message:{
                      text:currerntText,
                      slide:slide,
                      state:state
                  }
                }
            })

            setSubmit(true);
            
          //setChat(true);
        }}
      >
        Send Message
      </button>

      {
          submit?<div>Submitted</div>:<></>
      }

    </>
  );
};
export default DebugBox;
