
import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';


import { useState, useEffect } from "react";

const VisitContainer = ({ state, control, slide }) => {


  const [selectContainer, setSelectContainer] = useState(undefined);


  if  (!state.containers){
    state.containers = [];
  }

  if (selectContainer !== undefined){


    return <>
    <Navbar state={state} control={control} slide={slide}/>

    <div className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 " style={{ maxWidth: "25rem" }}>
      <div className=" flex-column w-100">

        <h3 className="text-center my-4">Container</h3><br/>

        <textarea onChange={(e)=>{
          
        }} className="form-control border border-secondary mt-n3" name="" rows="12" value={state.containers[selectContainer].entry} readOnly={true}></textarea>
           
        <button
          type="button"
          className="btn btn-block border  text-center btn-lg mt-4"
          onClick={()=>{
            setSelectContainer(undefined);
          }}
        >
          Back
        </button>
       


        <EmergencyResources />
      </div>
    </div>
  </>

  }



  return (
    <>

      <Navbar state={state} control={control} slide={slide}/>

      <div className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 " style={{ maxWidth: "25rem" }}>
        <div className=" flex-column w-100">

          <h3>Container  Library</h3><br />

            {
              state.containers.map((e, index)=>{
                return <div key={"entries"+index} className="btn btn-block  text-left btn-lg" onClick={()=>{
                  setSelectContainer(index);
                }}>


                <i className="fas fa-book-open mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}></i>
  
                {e.title}
              </div>
              })
            }

          <button 
          type="submit" 
          className="btn btn-block border  text-center btn-lg mt-4"
          onClick = {()=>{
            control.goWhere("writeContainer");
            }}
          >
            Add a Container
            </button>


            <button 
          type="submit" 
          className="btn btn-block border  text-center btn-lg mt-4"
          onClick = {()=>{
            control.goWhere("howDidItFeel")
          }}
          >
            {state.savePosition?"Next":"Back to Savyn"}
            
            </button>
            


         


          <EmergencyResources />
        </div>
      </div>







    </>
  );
};
export default VisitContainer;
