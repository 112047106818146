import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';

const YesNo = ({state, control, slide }) => {
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>


        <div
          className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
          style={{
              maxWidth:"25rem"
          }}
        >
          <div className=" flex-column w-100">
            
        <h3 className="mb-4">{slide.title}</h3>
        <p className="mb-4">{slide.description}</p>
        <button
            type="button"
            className="btn btn-block border  text-center btn-lg mb-2"
            onClick={()=>{
              control.goSlide(slide.yes);
            }}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-block border  text-center btn-lg mb-2"
          
            onClick = {()=>{
              control.goSlide(slide.no);
            }}
            >
            No
          </button>
          </div>

         <EmergencyResources/>
        </div>

    </>
  );
};
export default YesNo;
