import {useRef } from 'react'
import EmergencyResources from "../Components/EmergencyResources";
import Navbar from "../Components/Navbar";
import Sidepanel from "../Components/Sidepanel";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";





const Feedback = ({ state, control, slide }) => {

  const circleRef = useRef(null)

  return (
    <>
     <Navbar state={state} control={control} slide={slide}/>

<Row className="bg-img min-vh-100"  >
 
 <Sidepanel state={state} control={control} slide={slide}/>
 
 
   <div className="box">
     
   <div
        className="  container d-flex  justify-content-center  mh-100 "
        style={{
          maxWidth: "35rem"
        }}>
          <div className=" flex-column w-100">
                   
          <h1 className="mb-4">
          <i style={{fontSize:"24px", cursor:"pointer"}} 
            class="fas fa-arrow-left float-left mt-2"  
            onClick={()=>{
              control.goSlide(slide.complete);
              }}></i> {slide.title}</h1>
  
          <Form ref={circleRef} >
            <Form.Group controlId="userName">
              <Form.Label className="float-left">Your name</Form.Label>
              <Form.Control name="name" type="text" placeholder="Please type your name " />
            </Form.Group>

            <Form.Group controlId="userEmail">
              <Form.Label className="float-left">Your email address</Form.Label>
              <Form.Control name="email" type="text" placeholder="Please type your email address" />
            </Form.Group>

            <Form.Group controlId="userFeedback">
              <Form.Label className="float-left">
                Your feedback
              </Form.Label>
              <InputGroup>
                <FormControl name="feedback" placeholder="Please type your feedback"  as="textarea" aria-label="With textarea" />
              </InputGroup>
            </Form.Group>

         

 <Form.Group controlId="feedbackURL">
              <Form.Label className="float-left">Name of the page you're providing feedback on</Form.Label>
              <Form.Control name="url"  type="text"  placeholder="Please type the name of the page" />
            </Form.Group> 

            <Form.Group controlId="contactAgain">
              <Form.Label className="float-left">Can we contact you for follow up questions?</Form.Label>
              <Form.Control  name="followUp" as="select">
                <option>Yes</option>
                <option>No</option>
              </Form.Control>
            </Form.Group>

           
           

            <button
              onClick={() => {


let data = {
  sheet:"Feedback",
  name:circleRef.current.name.value,
  email:circleRef.current.email.value,
  feedback:circleRef.current.feedback.value,
  url:circleRef.current.url.value,
  followUp:circleRef.current.followUp.value,
  slide: JSON.stringify(slide), 
}
             

const requestOptions = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data)
};

fetch('/json', requestOptions)
  .then(response => response.json())
  .then(data => {
  
  });

  control.goSlide(slide.complete)
               





                


              }}
              type="button"
              className="btn  btn-outline-primary btn-block border text-center btn-lg mb-2"
            >
               {slide.complete.title}
            </button>
            <div style={{paddingTop:"50px"}}></div>
          </Form>
          <br/>
        <div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

<br/>
<div tabindex={18} className=" text-left " style={{width:"100%", fontSize:"12px" }}>
If you’re in crisis, please call the Toronto Distress Line at 416-408-4357, Canada Suicide Prevention Services at 1-833-456-4566, Kids Help Line  at 1-800-668-6868 or the Gerstein Crisis Centre at 416-929-5200. You may also choose to go to your local hospital emergency room or call 911.

</div>
<div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>


        </div>
        </div>
        </div>
      {/* <EmergencyResources /> */}
      </Row>
    </>
  );
};

export default Feedback;
