
import EmergencyResources from '../Components/EmergencyResources';
import withoutShowdow from "../Images/without_shadow.png";
import Navbar from '../Components/Navbar';
import Sidepanel from '../Components/Sidepanel';
import { useState } from "react";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

const Desc = ({ state, control, slide }) => {
  if (!slide){
    return <></>
  }
  return (
    <>
<Navbar state={state} control={control} slide={slide}/>
      
      <Row className="bg-img min-vh-100" >
       
       <Sidepanel state={state} control={control} slide={slide}/>
       
         <div className="box">
         <div
        className="  container d-flex  justify-content-center  mh-100 "
        style={{
          maxWidth: "35rem"
        }}>
          <div className=" flex-column w-100">
          <h1 className="mb-4">
          <i style={{fontSize:"24px", cursor:"pointer"}} 
            class="fas fa-arrow-left float-left mt-2"  
            onClick={()=>{
              control.goSlide(slide.complete);
              }}></i>
          {slide.title}</h1>
          <p style={{ fontSize:"16px"}} className="text-left mb-4"dangerouslySetInnerHTML={{ __html:slide.description}}></p>
          {/* <h2 style={{marginTop:"200px", fontSize:"20px"}} className="text-left">{slide.infoTitle}</h2> */}
          {/* <p style={{lineHeight:'17px', fontSize:"12px"}} className="text-left mb-4 "  dangerouslySetInnerHTML={{ __html:slide.info}}></p> */}
          {/* <p style={{lineHeight:'10px', fontSize:"8px"}} className="text-left mb-4 " dangerouslySetInnerHTML={{ __html:slide.citation}}></p> */}
        


          {/* <button
            type="button"
            className="btn btn-block border  text-center btn-lg mt-4"
            onClick={()=>{
              
            control.goSlide(slide.complete);
            }}
          >
            {slide?.complete?.title ? slide.complete.title:"Next"}
          </button> */}
          
          <p style={{ fontSize:"16px"}} className="text-left mb-4">
            Hello and welcome to SavynCare’s beta launch of our platform 
            where you can access tools to heal from trauma and build your 
            resilience by practising our grounding techniques. We noticed
             accessing free tools to help with trauma and anxiety can be 
             challenging. As <a style={{ cursor:"pointer"}} href="https://www.savyntech.com/" target="_blank"> founders</a> who are immigrants with lived 
             experience of trauma, our healing journey was long and 
             difficult, but made easier by such resources. As a way of
              giving back to our community, we’ve built these free 
              resources for you, so that you can begin your healing 
              journey too. 
              <br/><br />
              We’ve worked with <a style={{ cursor:"pointer",color:"#007bff"}}onClick={()=>{
              control.goWhere("advisors");
              }}>trauma therapists</a>  and researchers 
              to give you an effective set of tools aimed at helping
              you ground and have a calming response to triggers and
               stressors. We’re grateful for their expert advice.</p>
          <ul>
            <li>
           <p style={{lineHeight:'30px', fontSize:"16px"}} className="text-left m-0">70 percent of adults experience at least one traumatic event in their lifetime</p>
           </li>
           <li>
           <p style={{lineHeight:'30px', fontSize:"16px"}} className="text-left m-0">20 percent of people who experience a traumatic event will develop PTSD</p>
           </li>
           <li>
           <p style={{lineHeight:'30px', fontSize:"16px"}} className="text-left m-0">About 8 million people have PTSD in a given year</p>
           </li>
           <li>
           <p style={{lineHeight:'30px', fontSize:"16px"}} className="text-left m-0 ">1 in 13 people will develop PTSD at some point in their life</p>
           </li>
           </ul>
           <p style={{ fontSize:"16px"}} className="text-left mb-4">
           We’re researching and working with experts from University of Toronto,
            Sick Kids Research Institute and experienced trauma therapists to bring
             you digitized, proven clinical interventions that will help you heal 
             such as written exposure therapy and eye movement desensitization and 
             reprocessing (EMDR). These innovative clinical therapies have a proven 
             track record of healing trauma over shorter periods of time and with 
             greater efficacy than most interventions. They’re also used in refugee
              camps and prescribed to war veterans.
            <br/><br />
            These features will be released once we’ve successfully completed clinical testing, 
            which is currently underway. We're also working on providing these tools in different 
            languages so more people have access to them. Do check our current multilingual breathing 
            exercises in <a style ={{cursor:"pointer", color:"#007bff"}}onClick={()=>{ 
              control.goWhere("breathExerciseArabic");
              }}>Arabic</a> and <a style ={{cursor:"pointer", color:"#007bff"}}onClick={()=>{ 
                control.goWhere("breathExerciseMandarin");
                }}>Mandarin</a>. <a style ={{cursor:"pointer", color:"#007bff"}}onClick={()=>{
              control.goWhere("signUp");
              }}>Sign up</a> to stay connected with us and get 
            early access to these features.
            <br/><br />
             We appreciate you being here. 
            We hope we’ve helped you in some way. Please share it 
            with people and communities you think may need it. 
            If you have any <a style ={{cursor:"pointer", color:"#007bff"}}onClick={()=>{
              control.goWhere("feedbackForm");
              }}>feedback</a> please let us know. We look forward to hearing from you.

</p>
<br/>
<Table responsive="sm" style={{tableLayout:"fixed", width:"100%",borderSpacing:"10px 3rem", borderTop:"0",cellSpacing:"0", cellPadding:"0"}} >
<thead >
  <tr>
  <th > <img
  alt=""
  src="https://storage.googleapis.com/savyn-public/SM.png"
  width="120px"
  height="120px"

  className=" mb-2"
/>{' '} 
</th>
    <th > <img
  alt=""
  src="https://storage.googleapis.com/savyn-public/VS.png"
  width="120px"
  height="120px"

  className=" mb-2"
/>{' '} 
</th>
<th > <img
  alt=""
  src="https://storage.googleapis.com/savyn-public/YW.png"
  width="120px"
  height="120px"

  className=" mb-2"
/>{' '} 
</th>
    
  </tr>
</thead>
<tbody>
<tr>
  <td><p style={{ fontSize:"16px"}} className="text-center " >Sakeena Mihar​</p></td>
  <td><p style={{ fontSize:"16px"}} className="text-center " >Vic Shao-Chih Chiang ​</p></td>
  <td><p style={{ fontSize:"16px"}} className="text-center " >Yang Wang​</p></td>
</tr>
<tr>
  <td><p style={{ lineHeight:"1.25", fontSize:"14px"}} className="text-center" >Founder and CEO</p></td>
  <td><p style={{ lineHeight:"1.25", fontSize:"14px"}} className="text-center" >Founder and Chief Scientist</p></td>
  <td><p style={{ lineHeight:"1.25", fontSize:"14px"}} className="text-center" >Founder and CMO</p></td>
</tr>
</tbody>
</Table>
              <br />
              <h3>Supported By</h3>
              <div className="row justify-content-center" > 
                <img src="https://storage.googleapis.com/savyn-public/CFC_IRP-ENG-Colour.webp" className="m-3" height="60px" width="313px" />
              </div>
              <div className="row justify-content-center">
                <img src="https://storage.googleapis.com/savyn-public/SocialVenture-rgb.png" className="m-3" height="45px" width="156px" />
                <img src="https://storage.googleapis.com/savyn-public/BiomedicalZoneLogo.png" className="m-3" height="60px" width="159px" />
                <img src="https://storage.googleapis.com/savyn-public/Ryerson-rgb.png" className="m-3" height="60px" width="124px" />
              </div>
              <div className="row justify-content-center align-items-center">
                <img src="https://storage.googleapis.com/savyn-public/University_of_Toronto-Logo.wine.png" className="mx-3" height="100px" width="215px" />
                <img src="https://storage.googleapis.com/savyn-public/sickkids-logo-desktop.webp" className="mx-3" height="42" width="140px" />
              </div>
              <br />
              <h3>Awards</h3>
              <div className="row justify-content-center">
                <img src="https://storage.googleapis.com/savyn-public/cooperathon_log.webp" className="m-3" />
              </div>
           
 <br/>
        <div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

<br/>
<div className=" text-left " style={{lineHeight:'17px',width:"100%", fontSize:"12px" }}>
If you’re in crisis, please call the Toronto Distress Line at 416-408-4357, Canada Suicide Prevention Services at 1-833-456-4566, Kids Help Line  at 1-800-668-6868 or the Gerstein Crisis Centre at 416-929-5200. You may also choose to go to your local hospital emergency room or call 911.

</div>
<div style={{width:"100%", paddingBottom:"40px", opacity:"0"}}>{"x"}</div>

          </div>
      </div>
      </div>
          {/* <EmergencyResources /> */}

</Row>
 
     </>
  );
};





export default Desc;
