import EmergencyResources from "../Components/EmergencyResources";
import Navbar from "../Components/Navbar";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import { useState } from "react";


const TrumaCreator = ({ state, control, slide }) => {
  let [trigger, setTrigger] = useState("");
  let [noTrigger, setNoTrigger] = useState("");
  const RowHistory = ({ index, event }) => {
    return (
      <tr>
        <td>{event}</td>
        <td style={{ 
            textAlign: "left" }}>
          <Form.Check
            inline
            name={"textarea" + index}
            label=""
            type="textarea"
            id={"inline-textarea-" + index}
          />
        </td>
      </tr>
    );
  };
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>
      <Container className="d-flex align-items-center justify-content-center vh-100 mh-100 ">
        <div className=" flex-column w-100">



        <h3>What would you like to work on today?</h3>

          <br />
          <Form>
   
         Indicate below which traumatic event still causes you the most distress.           List key events from your trauma history.
 
              {/* <Table responsive="sm">

              
                <th></th>
                <th></th>
              
          
            <tbody>
              <RowHistory
                index={0}
              />


              </tbody>
          </Table> */}


          <br />
          <textarea name="" id="" cols="50" rows="10"></textarea>
          {/* <Form.Control type="text" placeholder="" onChange={(e)=>{
                setTrigger(e.target.value);
              }} value={trigger}/> */}
          {/* <Form> */}
            <Form.Group controlId="userName">
              <Form.Label>Name of memory for Therapist</Form.Label>
              <Form.Control type="text" placeholder="This name will appear for the Therapist" onChange={(e)=>{
                setTrigger(e.target.value);
              }} value={trigger}/>
            </Form.Group>

            <Form.Group controlId="userName">
              <Form.Label>Name of memory for Yourself</Form.Label>
              <Form.Control type="text" placeholder="Choose a non triggering name" onChange={(e)=>{
                setNoTrigger(e.target.value);
              }} value={noTrigger} />
            </Form.Group>

            <button
              onClick={() => {
                control.goSlide(slide.complete, {
                  trigger,noTrigger
                })
              }}
              type="button"
              className="btn btn-block border text-center btn-lg mb-2"
            >
               {slide.complete.title}
            </button>
            <div style={{paddingTop:"50px"}}></div>
          </Form>
        </div>
        
      </Container>
      <EmergencyResources />
    </>
  );
};

export default TrumaCreator;


// then have another text box with title saying "select what you would like to work on today"
// "name of trauma you are working on for the therapist"
// "name for you(if you want it to be different)