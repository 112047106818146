import EmergencyResources from '../Components/EmergencyResources';
import Navbar from '../Components/Navbar';
import {
    Container,
    Form,
    Col,
    Row,
    Table,
    InputGroup,
    FormControl,
  } from "react-bootstrap";
  import { useState } from "react";
const Consent = ({ state, control, slide }) => {
    let [noConsent, setNoConsent] = useState(false);
    let [consent, setConsent] = useState(false);
  return (
    <>
      <Navbar state={state} control={control} slide={slide}/>

<div
  className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
  style={{ maxWidth: "25rem" }}
>
  <div className=" flex-column w-100">
    <h3>Sign consent with Savyn for EMDR</h3>
    <br />

    <div  className="my-2" >

<input className="my-2" type="radio" name="rad3" id="agree" value="agree" onClick={()=>{
    setNoConsent(false);
    setConsent(true);
}}/> I agree to use Savyn's EMDR sessions and understand that I am responsible for taking each session. I also understand Savyn is not liable for the outcomes of this treatment.
</div>

<div className="my-2">
<input type="radio" name="rad3" id="disagree" value="disagree" onClick={()=>{
    setNoConsent(true);
    setConsent(false);
}}/> I disagree.
</div>

{(noConsent)? <p>We are sorry you did not consent to EMDR sessions with Savyn. We cannot proceed without your consent. Until you decide you can continue to use our breathing exercise and written exposure therapy. You can come back to consent anytime within our app to use our EMDR sessions.</p>:<></>}

{(consent)?<button
      type="submit"
      className="btn btn-block border  text-center btn-lg mt-4"
      onClick={() => {
        control.goSlide(slide.complete);
      }}
    >
      {slide.complete.title}
    </button>:<></>}


    {(!consent)?<button
      type="submit"
      className="btn btn-block border  text-center btn-lg mt-4"
      onClick={() => {
        control.goSlide(slide.back);
      }}
    >
      {slide.back.title}
    </button>:<></>}

    <EmergencyResources />
  </div>
</div>
    </>
  );
};
export default Consent;