class State {
    constructor(params) {
      if (params) {
        this.bookMark = params.bookMark ? params.bookMark : {};
        this.entries = params.entries ? params.entries : [];
        this.audio = params.audio ? params.audio : [];
        this.name = params.name;
        this.emdrSessions = params.emdrSessions ? params.emdrSessions : [];
        this.wetSessions = params.wetSessions ? params.wetSessions : [];
        this.containers = params.containers ? params.containers : [];
        this.wet = params.wet ? params.wet : [];
        this.sessionMissed = params.sessionMissed ? params.sessionMissed : {};
        this.writtenExposure = params.writtenExposure ? params.writtenExposure: [];
        this.notFirstTime = params.notFirstTime;
        this.assessment = params.assessment ? params.assessment : 0;
        this.bdiAssessment = params.bdiAssessment ? params.bdiAssessment : 0;
  
        this.consent = params.consent;
        this.EMDRSession = params.EMDRSession;
        this.WETSession = params.WETSession;
        this.savePosition = params.savePosition;
      } else {
        this.bookMark = {};
        this.containers = [];
        this.wet = [];
        this.entries = [];
        this.audio = [];
        this.name = "";
        this.emdrSessions = [];
        this.wetSessions = [];
        this.sessionMissed = {};
        this.writtenExposure = [];
        this.notFirstTime = false;
        this.consent = false;
        this.assessment = 0;
        this.bdiAssessment = 0;
        this.EMDRSession = undefined;
        this.WETSession = undefined;
        this.savePosition = undefined;
      }
    }
    clearPosition = () => {
      this.savePosition = undefined;
      return this;
    };
    addPrerecorded = () => {
      this.entries = ["Rember the good times", "A grey sky"];
      this.notFirstTime = true;
      return this;
    };
    addWrittenExposure = (text, index) => {
      if (!index) {
        this.writtenExposure.push(text);
      } else {
        while (index >= this.writtenExposure.length) {
          this.writtenExposure.push("");
        }
        this.writtenExposure[index] = text;
      }
    };
    addWrittenExposure = (text, index) => {
      if (!index) {
        this.wetSessions.push(text);
      } else {
        while (index >= this.wetSessions.length) {
          this.wetSessions.push("");
        }
        this.wetSessions[index] = text;
      }
    };
    addEmdr = ({ trigger, noTrigger }) => {
      this.emdrSessions.push({
        trigger,
        noTrigger,
        lastSession: 1,
      });
    };
    clearSession = () => {
      this.EMDRSession = undefined;
      return this;
    };
    setConsent = () => {
      this.consent = true;
      return this;
    };
  
    setName = (name) => {
      this.name = name;
      return this;
    };
  }



  export default State;