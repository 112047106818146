import logo from "./logo.svg";
import "./App.css";

import {
  Container,
  Button,
  Form,
  Col,
  Row,
  Modal,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import ChatBox from "./Chat/ChatBox";
import Where from "./Savyn/Where";
import State from "./Savyn/State";
import Switcher from "./Savyn/Switcher";
import Debugger from "./Savyn/Debugger";
import Sidebar from "./Savyn/Sidebar";
import TermsPopup from "./Components/TermsPopup";
import SignupBtn from "./Chat/SignupBtn";
import useKeypress from "react-use-keypress";

import { useState, useEffect } from "react";

import { HashRouter as Router, useHistory } from "react-router-dom";

import Map from './Savyn/Map';

function App() {




  
  return (
    <Router>
      <AppWrapped />
    </Router>
  );
  
}

let _newWhere = localStorage.getItem("hackAThon")
  ? JSON.parse(localStorage.getItem("hackAThon"))
  : {
      homePage: {
        type: "homePage",
        url: "/",
        title: "Welcome To The Hackathon",
        buttons: [
          {
            title: "Test Page",
            slide: "testPage",
          },
        ],
      },

      testPage: {
        type: "desc",
        title: "This is a Test Page",
        complete: {
          title: "Back to Home",
          slide: "homePage",
        },
      },
      noPage: {
        type: "desc",
        title: "There is no page",
        complete: {
          title: "Go Back",
          slide: "homePage",
        },
      },
    };

function AppWrapped() {
  let history = useHistory();
  let testAuc = undefined;
  // JSON.parse(localStorage.getItem("debugState"));
  if (!testAuc) {
    testAuc = {
      slide: "homePage",
      state: new State(),
    };
  }

  let [where, setWhere] = useState(Where);
  let [newWhere, setNewWhere] = useState(_newWhere);
  let [state, setState] = useState(new State(testAuc.state));
  let [_slide, setSlide] = useState(testAuc.slide);
  let [tabPress, setTabPressed] = useState(0);
  let [hackAThon, setHackAThon] = useState(false);


  useKeypress("Escape", () => {
    // setTabPressed(tabPress + 1);
  });



  const changeOptions = (vars) => {
    if (vars.state) {
      setState(vars.state);
    }

    if (vars.slide) {
      setState(vars.slide);
    }
  };

  if (hackAThon){
    where=newWhere;
  } else {
    setWhere=setNewWhere;
  }

  let slide = where[_slide];
  if (!slide) {
    slide = where["homePage"];
  } else {
  }

  const control = {
    setEMDRSession: (text) => {
      let _state = new State(state);
      _state.EMDRSession = text;
      setState(_state);
    },
    // setWETSession: (text) => {
    //   let _state = new State(state);
    //   _state.WETSession = text;
    //   setState(_state);
    // },
    
    clearState: () => {
      setState(new State());
      localStorage.setItem("debugState", undefined);
    },
    addName: (name) => {
      let _state = new State(state);
      _state.name = name;
      setState(_state);
    },
   

    addTrigger: ({ trigger, noTrigger }) => {
      if (noTrigger === "") {
        noTrigger = "Unnamed Trigger";
      }
      if (trigger === "") {
        trigger = "Unnamed Trigger";
      }

      state.addEmdr({ trigger, noTrigger });
      setState(state);
    },
    setAssessment: (value) => {
      let _state = new State(state);
      _state.assessment = value;
      state.assessment = value;
      setState(_state);
    },
    setBDIAssessment: (value) => {
      let _state = new State(state);
      _state.bdiAssessment = value;
      state.bdiAssessment = value;
      setState(_state);
    },
    addWrittenExposure: (text, index) => {
      state.addWrittenExposure(text, index);
      setState(state);
    },

    saveContainer: (entry, title) => {
      if (!state.containers) {
        state.containers = [];
      }
      state.containers.push({
        title,
        entry,
      });
      let _state = new State(state);
      setState(_state);
    },
    saveWet: (entry, title) => {
      if (!state.wet) {
        state.wet = [];
      }
      state.wet.push({
        title,
        entry,
      });
      let _state = new State(state);
      setState(_state);
    },

    saveEntry: (entry, title) => {
      if (!state.entries) {
        state.entries = [];
      }
      state.notFirstTime = true;
      state.entries.push({
        title,
        entry,
      });
      setState(state);
    },

    saveAudio: (audio) => {
      if (!state.audio) {
        state.audio = [];
      }
      state.notFirstTime = true;
      state.audio.push(audio);
      setState(state);
    },
    editEntry: (entry, index, title) => {
      if (!state.entries) {
        state.entries = [];
      }

      if (state.entries[index]) {
        state.entries[index] = { title, entry };
      }
      setState(state);
    },
    goAudioSafeSpace: (e, index) => {
      history.push("/safeSpaceAudio/" + index);
    },
    goTextSafeSpace: (e, index) => {
      history.push("/safeSpaceText/" + index);
    },

    setBookMark: (bookMark, slide) => {
      if (!state.bookMark) {
        state.bookMark = {};
      }
      state.bookMark[bookMark] = slide;

      let _state = new State(state);
      setState(_state);
    },

    goBookMark: (bookMark) => {
      if (state.bookMark) {
        if (state.bookMark[bookMark]) {
          control.goWhere(state.bookMark[bookMark]);
        }
      } else {
        control.goWhere("homePage");
      }
    },

    goCheckOut: () => {
      if (state.savePosition) {
        control.goWhere(state.savePosition);
        return;
      }
      control.goWhere("howSafeDoYouFeel");
    },

    goWhere: (string) => {
      let aSlide = where[string];
      console.log(aSlide);
      if (aSlide.url) {
        history.push(aSlide.url);
      }

      localStorage.setItem(
        "debugState",
        JSON.stringify({
          slide: string,
          state,
        })
      );
      setSlide(string);
    },

    say: (text) => {
      var msg = new SpeechSynthesisUtterance();
      msg.text = text;
      window.speechSynthesis.speak(msg);
    },

    goSlide: (object, vars) => {
      if (!object) {
        setSlide("noPage");
        return;
      }

      let aSlide = where[object.slide];

      if (!aSlide) {
        setSlide("noPage");
        return;
      }

      if (object.action) {

          if (object.action.type === "savePosition") {
            let _state = new State(state);
            _state.savePosition = object.action.slide;
            setState(_state);
          }
          if (object.action.type === "setTrigger") {
            let _state = new State(state);
            _state.EMDRSession = undefined;
            _state.emdrSessions.push({
              trigger: vars.trigger,
              noTrigger: vars.noTrigger,
              lastSession: 1,
            });

            setState(_state);
          }
          if (object.action.type === "setConsent") {
            let _state = new State(state);
            _state.consent = true;
            setState(_state);
          }
          if (object.action.type==="setAgreement"){
            let _state = new State(state);
            _state.agreement = true;
            setState(_state);
          }
        
      }

      if (aSlide.url) {
        history.push(aSlide.url);
      }

      if (object.bookmarks) {
        object.bookmarks.forEach((bookMark) => {
          control.setBookMark(bookMark.bookMark, bookMark.slide);
        });
      }

      if (object.bookMark) {
        if (state.bookMark[object.bookMark]) {
          const keyForBookMark = state.bookMark[object.bookMark];
          aSlide = where[keyForBookMark];

          localStorage.setItem(
            "debugState",
            JSON.stringify({
              slide: keyForBookMark,
              state,
            })
          );
          setSlide(keyForBookMark);
          return;
        }
      }

      localStorage.setItem(
        "debugState",
        JSON.stringify({
          slide: object.slide,
          state,
        })
      );
      setSlide(object.slide);
    },
  };

  if (tabPress % 2) {
    return (
      <Row>
        <Col>
          <Debugger
            setSlide={setSlide}
            where={where}
            setWhere={setWhere}
            history={history}
            state={state}
            _slide={_slide}
            slide={slide}
            changeOptions={changeOptions}
            testAuc={testAuc}
            hackAThon={hackAThon} 
            setHackAThon={setHackAThon}
          />
        </Col>
        <Col>
          <InnerApp
            history={history}
            control={control}
            state={state}
            setState={setState}
            slide={slide}
            setSlide={setSlide}
            where={where}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <ChatBox  control={control} state={state} slide={slide}/>
      <SignupBtn  control={control} state={state} slide={slide}/>
       <TermsPopup control={control} state={state} slide={slide}/> 
      <InnerApp
       hackAThon={hackAThon} 
       control={control}
        history={history}
        state={state}
        setState={setState}
        slide={slide}
        setSlide={setSlide}
        where={where}
      />
    </>
  );
}

function InnerApp({ history, control, state, setState, slide, setSlide, where }) {
  return (


    <Switcher
    control={control}
      history={history}
      state={state}
      setState={setState}
      slide={slide}
      setSlide={setSlide}
      where={where}
    />

  );
}

export default App;
